import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../utils'
import './search.component.css'

class Search extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      value: props.value ? props.value : '',
      isFocused: false
    }

    this.inputReference = React.createRef()
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleInputFocus = this.handleInputFocus.bind(this)
    this.handleRefocusInput = this.handleRefocusInput.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.getStyles = this.getStyles.bind(this)
  }

  /**
   * ComponentDidUpdate
   * Handle manual clear of the search value
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { value } = this.props

    if (!value && prevProps.value) {
      this.setState({
        value: ''
      })
    }
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount () {
    this.queryTimeout && clearTimeout(this.queryTimeout)
  }

  /**
   * Handle input change
   * @param event
   */
  handleInputChange (event) {
    const { minLength, buffer, onQueryChange, onClear } = this.props

    const target = event.target
    const value = target.value
    const defaultBuffer = 400

    this.setState({
      value
    })

    this.queryTimeout && clearTimeout(this.queryTimeout)
    this.queryTimeout = setTimeout(() => {
      if (value.trim().length >= (minLength || 0)) {
        onQueryChange && onQueryChange(value)
      } else if (value.trim().length === 0) {
        onClear && onClear()
      }
    }, (isNaN(buffer)) ? defaultBuffer : buffer)
  }

  /**
   * Handle text input keyPress event
   * @param event
   */
  handleKeyDown (event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      return this.state.value && this.handleClear()
    }
  }

  /**
   * Handle text input focus
   * @param event
   */
  handleInputFocus (event) {
    const { onInputFocus } = this.props

    const value = event.target.value
    event.target.value = ''
    event.target.value = value

    this.setState({
      isFocused: true
    })

    onInputFocus && onInputFocus()
  }

  /**
   * Handle refocusing of input
   */
  handleRefocusInput () {
    const Input = this.inputReference && this.inputReference.current

    Input && Input.focus()
  }

  /**
   * Handle text input blur
   */
  handleInputBlur () {
    const { onInputBlur } = this.props

    this.setState({
      isFocused: false
    })

    onInputBlur && onInputBlur()
  }

  /**
   * Handle Search selection clear
   */
  handleClear () {
    const { onClear } = this.props

    this.setState({
      value: ''
    })

    this.handleRefocusInput()
    onClear && onClear()
  }

  /**
   * Get Search component styles
   * @returns {Object}
   */
  getStyles () {
    const { isFocused } = this.state
    const { width, focusWidth, height, zIndex } = this.props

    return {
      base: {
        width: (focusWidth && isFocused) ? focusWidth : width,
        height,
        zIndex
      },
      input: {
        width: height ? `calc(100% - ${height}px)` : null,
        height,
        lineHeight: height ? `${height}px` : null
      },
      icon: {
        width: height,
        lineHeight: height ? `${height}px` : null
      }
    }
  }

  /**
   * Render Search Component
   * @returns {*}
   */
  render () {
    const { className, theme, disabled, placeholder } = this.props
    const { value } = this.state

    const styles = this.getStyles()
    const baseClassName = getClassName('search', [
      { condition: className, trueClassName: className },
      { condition: theme, trueClassName: `search--${theme}` },
      { condition: value, falseClassName: 'search--empty' },
      { condition: disabled, trueClassName: 'search--disabled' }
    ])

    return (
      <div className={baseClassName} style={styles.base}>
        <div className='search_inner'>
          <div className='search_icon' style={styles.icon}>
            <i className='icon-search' />
          </div>
          <input
            ref={this.inputReference}
            className='search_input'
            style={styles.input}
            name='value'
            placeholder={placeholder || 'Search by keyword'}
            maxLength={32}
            value={value}
            readOnly={disabled}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleInputChange}
          />
          {value && (
            <i className='search_clear material-icons' onClick={this.handleClear}>close</i>
          )}
        </div>
      </div>
    )
  }
}

Search.propTypes = {
  /**
   * Optional Class Name
   */
  className: PropTypes.string,

  /**
   * Theme for the component
   * Add more as you see fit
   */
  theme: PropTypes.oneOf(['slate', 'dark-slate']),

  /**
   * Box model props
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  zIndex: PropTypes.number,

  /**
   * Used to determine width of the component while the input is focused
   */
  focusWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Component's input value
   * Used to set an initial state
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),

  /**
   * Placeholder text for the input for when the input is empty
   */
  placeholder: PropTypes.string,

  /**
   * The minimum character length required before onQueryChange is triggered
   */
  minLength: PropTypes.number,

  /**
   * The delay between when a character is typed into the input and the triggering of onQueryChange
   */
  buffer: PropTypes.number,

  /**
   * Use this prop to render the component visible but inactive
   */
  disabled: PropTypes.bool,

  /**
   * Callbacks triggered during component interaction
   */
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  onClear: PropTypes.func,
  onQueryChange: PropTypes.func
}

Search.defaultProps = {
  theme: 'slate',
  value: '',
  placeholder: 'Search',
  minLength: 1,
  buffer: 400
}

export default Search
