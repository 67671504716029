import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../config'
import { GET } from '../index'

/**
 * Subscription Action Type Constants
 * @type {string}
 */
export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST'
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS'
export const GET_SUBSCRIPTIONS_FAILURE = 'GET_SUBSCRIPTIONS_FAILURE'

/**
 * Dispatch SUBSCRIBE_TOPIC
 */
export const getSubscriptions = (profileId, params) => {
  let headers = HEADERS

  if (params && params.token) {
    headers = { ...HEADERS, ...{ Authorization: `Bearer ${params.token}` } }
  }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/subscription?profile=${profileId}`,
      method: GET,
      headers: headers,
      types: [
        GET_SUBSCRIPTIONS_REQUEST,
        GET_SUBSCRIPTIONS_SUCCESS,
        GET_SUBSCRIPTIONS_FAILURE
      ]
    }
  })
}

