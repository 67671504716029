import {
  GET_BOOKMARK_REQUEST,
  GET_BOOKMARK_SUCCESS,
  GET_BOOKMARK_FAILURE,
  IDLE, FETCHING, FETCHED, FAILED
} from '../../actions'
import { uniqBy } from 'lodash'

const initialState = {
  data: [],
  status: IDLE
}

const bookmark = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKMARK_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_BOOKMARK_SUCCESS:
      if(action.payload.success === false){
        return {
          data: [],
          status: FETCHED
        }
      }else{
        return {
          data: uniqBy([...action.payload.data], '_id'),
          status: FETCHED
        }
      }
    case GET_BOOKMARK_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default bookmark
