import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../config'
import { GET } from '../index'

/**
 * Topic Action Type Constants
 * @type {string}
 */
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE'

/**
 * Dispatch GET_TOPIC_LIST_REQUEST
 */
export const fetchCategoriesWithTopics = () => ({
	[RSAA]: {
		endpoint: `${API_URL}/public/category`,
		method: GET,
		headers: HEADERS,
		types: [
			GET_CATEGORIES_REQUEST,
			GET_CATEGORIES_SUCCESS,
			GET_CATEGORIES_FAILURE
		]
	}
})
