import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { getClassName } from '../../utils/ui/ui.util'
import { Button } from '../index'
import './modal.component.css'

/**
 * Modal Component
 */
class Modal extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      isBeingHidden: false
    }

    this.appBodyContainer = document.getElementsByTagName('body')[0]
    this.modalContainer = document.createElement('div')
    this.modalContainer.className = 'modal-portal'

    if (props.isVisible) {
      this.appBodyContainer.classList.add('overflow-hidden')
      this.appBodyContainer.appendChild(this.modalContainer)
    }

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleStartHide = this.handleStartHide.bind(this)
    this.handleHide = this.handleHide.bind(this)
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  /**
   * ComponentDidUpdate
   * Recalculate noData state if a new set of options is provided
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { isVisible } = this.props

    if (!prevProps.isVisible && isVisible) {
      this.handleOpen()
    }

    if (prevProps.isVisible && !isVisible) {
      this.handleStartHide()
    }
  };

  /**
   * ComponentWillUnmount
   * Destroy modal portal as to note pollute the DOM
   */
  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyDown)

    if (this.props.isVisible) {
      this.appBodyContainer.removeChild(this.modalContainer)
    }
  }

  /**
   * Handle keydown event
   * @param event
   */
  handleKeyDown (event) {
    event = event || window.event

    switch (event.key) {
      case 'Escape':
      case 'Esc':
        const { disableEscToClose, onHide } = this.props
        return !disableEscToClose && onHide()
      default:
        return false
    }
  }

  /**
   * Handle Modal collapse
   */
  handleOpen () {
    this.appBodyContainer.classList.add('overflow-hidden')
    this.appBodyContainer.appendChild(this.modalContainer)
  }

  /**
   * Handle start of Modal isBeingHidden process
   * This is done to provide a fadeout animation
   */
  handleStartHide () {
    const { isBeingHidden } = this.state

    if (isBeingHidden) {
      return
    }

    this.setState({
      isBeingHidden: true
    }, () => {
      setTimeout(() => {
        this.setState({
          isBeingHidden: false
        })

        this.handleHide()
      }, 350)
    })
  }

  /**
   * Handle Modal collapse
   */
  handleHide () {
    this.appBodyContainer.classList.remove('overflow-hidden')
    this.appBodyContainer.removeChild(this.modalContainer)
  }

  /**
   * Render Modal Component
   * @returns {*}
   */
  render () {
    const { children, className, fullscreen, includeExit, animation, onHide, title } = this.props
    const { isBeingHidden } = this.state

    const baseClassName = getClassName('modal', [
      { condition: className, trueClassName: className },
      { condition: fullscreen, trueClassName: 'modal--fullscreen' },
      { condition: animation, trueClassName: `modal--${animation}` },
      { condition: isBeingHidden, trueClassName: 'modal--hiding' }
    ])

    return ReactDOM.createPortal((
      <div className={baseClassName}>
        <div className='modal_mask' onClick={onHide} />
        <div className='modal_container'>
          <div className='modal_header'>
            <div className='modal_title'>{title}</div>
            {includeExit && (
              <Button className='modal_exit' theme='transparent' icon='close' onClick={onHide} />
            )}
          </div>
          <div className='modal_children'>
            {children}
          </div>
        </div>
      </div>
    ), this.modalContainer)
  }
}

Modal.propTypes = {

  /**
   * A custom className to add to the component
   */
  className: PropTypes.string,

  /**
   * Used to show / hide the Modal component
   */
  isVisible: PropTypes.bool.isRequired,

  /**
   * Used to determine the entrance animation for the Modal component
   */
  animation: PropTypes.oneOf(['fade', 'slide-up']),

  /**
   * Used to paint the Modal component in full-screen
   */
  fullscreen: PropTypes.bool,

  /**
   * Used to render a closing 'x' inside the modal
   */
  includeExit: PropTypes.bool,

  /**
   * Used to disable use of the Esc key to hide the modal
   */
  disableEscToClose: PropTypes.bool,

  /**
   * A callback for when the user attempts to hide the Modal component
   */
  onHide: PropTypes.func.isRequired,


  /**
   * Text to display in the header of the modal
   */
  title: PropTypes.string
}

Modal.defaultProps = {
  animation: 'fade',
  disableEscToClose: false
}

export default Modal
