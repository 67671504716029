import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { logUserActions } from '../../utils'
import TattleLink from '../link/link.component'
import ShareComponent from '../share/share.component'
import { Button, Title } from '../index'
import { getLnk } from '../../utils'
import './articleList.component.css'

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ss',
    ss: '%ss',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: '%dM',
    MM: '%dM',
    y: '%dY',
    yy: '%dY'
  }
})

const getLink = (article, showName) => {
  const { _topic, feed_link } = article
  let link

  if (_topic && _topic.seo_name) {
    link = <TattleLink
        className='article_action article_action--topic'
        to={`/topic/${encodeURI(_topic.seo_name)}`}
    >{_topic.label}</TattleLink>
  }


  if (showName) {
    link = <TattleLink
      className='article_action article_action--topic'
      to={feed_link.href}
      onClick={() => logUserActions('source')}
      target={'_blank'}
    >{feed_link.title}</TattleLink>
  }

  return link
}

class ArticleList extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      shareOpen: false,
      sharingOptions: {
        url: '',
        title: '',
        content: ''
      }
    }

    this.handleArticleClick = this.handleArticleClick.bind(this)
    this.handleShareClick = this.handleShareClick.bind(this)
    this.handleShareClose = this.handleShareClose.bind(this)
    this.handleBookMarkClick = this.handleBookMarkClick.bind(this)
    this.handleShowContentClick = this.handleShowContentClick.bind(this)
  }

  handleArticleClick (article) {
    const seo_name = article.seo_name
    const title_seo_name = article._topic.seo_name

    if (article.feed_iframe) {
      window.open(`/topic/${title_seo_name}/${seo_name}`, '_self')
    } else {
      const link = getLnk(article) || ''

      window.open(link, '_blank')
    }
  }

  handleShareClick (article) {
    const title_seo_name = article._topic.seo_name
    const { seo_name, title, content_text, feed_iframe } = article

    let url = '';

    if(feed_iframe){
      url = `topic/${title_seo_name}/${seo_name}`;
    }else{
      url = article.shortcut_url.length === 0 ? `topic/${title_seo_name}` : article.shortcut_url[0].href;
    }

    this.setState({
      shareOpen: !this.state.shareOpen,
      sharingOptions: {
        url: url,
        title: title,
        content: content_text
      }
    })
  }

  handleShareClose () {
    const { profile } = this.props
    const { sharingOptions } = this.state
    const article = sharingOptions.title

    logUserActions('shareArticle', article, profile.user)

    this.setState({
      shareOpen: false,
      sharingOptions: {
        url: '',
        title: '',
        content: ''
      }
    })
  }

  handleBookMarkClick (article) {
    const { profile, auth, createBookmark, removeBookmark } = this.props

    if (!auth || !auth.isLoggedIn()) {
      return false
    }

    if (article.bookmark) {
      removeBookmark && removeBookmark(article.bookmark)
    } else {
      const body = {
        profileId: profile.user && profile.user._id,
        articleId: article._id,
        topicId: article._topic._id
      }

      createBookmark && createBookmark(body)
    }
  }

  handleShowContentClick (e) {
    const target = e.target.parentElement.parentElement
    const className = target.className
    const iconTarget = e.target

    if (className.indexOf('show') > -1) {
      target.className = className.replace('show', '').trim()
    } else {
      target.className = [].concat(className, 'show').join(' ')
    }

    if (iconTarget.className === 'icon-expand_more') {
      iconTarget.className = 'icon-expand_less'
    } else {
      iconTarget.className = 'icon-expand_more'
    }
  }

  render () {
    const { articles, includeSectionTitle, title, showName, auth } = this.props
    const { shareOpen, sharingOptions } = this.state
    const noData = !articles || !articles.length

    const sectionTitle = title || 'Articles'
    const authenticated = auth.isLoggedIn()

    // console.log(authenticated)
    return (
      <section className='article'>
        {includeSectionTitle && <Title>{sectionTitle}</Title>}
        <ul className='article_list'>
          {!noData ? articles.map((article) => {
            const { _id, title, images, _topic, publish_date, content_text } = article
            let thumbnail = images && images.length && images[0].src
            const link = getLnk(article)

            if (!thumbnail) {
              thumbnail = _topic.thumbnail
            }

            return (
              <li key={`article_item--${_id}`} className='article_list_item'>
                <div className={'article_item'}>
                    <div
                      className='article_thumbnail'
                      style={{ backgroundImage: `url(${thumbnail})` }}
                      onClick={() => this.handleArticleClick(article)}
                    >
                    {/* Play button thumbnail overlay for video articles */}
                    {article.feed_type === "youtube"?
                      <div className='article_thumbnail_video_overlay'>
                        <svg className="article_thumbnail_video_overlay_svg" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M16 32C24.82 32 32 24.82 32 16C32 7.18 24.82 0 16 0C7.18 0 0 7.18 0 16C0 24.82 7.18 32 16 32ZM12 7L24 16L12 25V7Z" fill="#EEEEEE"/>
                        </svg>
                        <svg className="article_thumbnail_video_overlay_svg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40ZM20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4ZM16 11V29L28 20L16 11Z" fill="#EEEEEE"/>
                        </svg>
                      </div>
                      :
                      null
                    }
                    </div>
                  <div className='article_details'>
                    <h4
                      tabIndex={0}
                      className='article_title'
                      onClick={() => this.handleArticleClick(article)}
                      onAuxClick={() => this.handleArticleClick(article)}
                    />
                    <div className='article_details'>
                      <h4
                        tabIndex={0}
                        className='article_title'
                        onClick={() => this.handleArticleClick(article)}
                        onAuxClick={() => this.handleArticleClick(article)}
                      >{title}</h4>
                      {getLink(article, showName)}
                      {(publish_date) ?
                        <span className='article_action article_action--date'>{moment(publish_date).fromNow()}</span>
                        : ''
                      }
                      <Button
                        theme='transparent'
                        icon='share'
                        className={'article_action share-btn'}
                        onClick={() => this.handleShareClick(article)}
                      />
                      {(authenticated) ?
                        <Button
                          theme='transparent'
                          icon='bookmark'
                          className={'article_action bookmark-btn ' + (article.bookmark ? 'active' : '')}
                          onClick={() => this.handleBookMarkClick(article)}
                        />
                        : ''
                      }
                      <Button
                        linkTo={link}
                        theme='transparent'
                        icon='launchopen_in_new'
                        className={'article_action launch-btn'}
                      />
                    </div>
                  </div>
                  {(content_text) ?
                    <div className={'article_item-content'}>
                      <div className={'content-body'}>
                        <Button
                          theme='transparent'
                          icon='expand_more'
                          className={'article_action content-btn'}
                          onClick={this.handleShowContentClick}
                        />
                        <div className={'short-body'}>
                          {content_text + '...'}
                          <span
                            className={'read-more'}
                            onClick={() => this.handleArticleClick(article)}
                            onAuxClick={() => this.handleArticleClick(article)}
                            >
                              read more
                            </span>
                        </div>
                      </div>
                    </div>
                    : ''}
                </div>
                </li>
            )
          }) : (
            <li className='article_item article_item--no-data'>No articles available.</li>
          )}
        </ul>
        <ShareComponent
          isVisible={shareOpen}
          handleShareClose={this.handleShareClose}
          type={'article'}
          {...sharingOptions}
        />
      </section>
    )
  }
}

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.array,
    publish_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    feed_link: PropTypes.object,
    _topic: PropTypes.object
  })),
  includeSectionTitle: PropTypes.bool,
  onArticleSelect: PropTypes.func,
  createBookmark: PropTypes.func,
  removeBookmark: PropTypes.func,
  profile: PropTypes.object,
  showName: PropTypes.bool
}

ArticleList.defaultProps = {
  articles: []
}

export default ArticleList
