import moment from 'moment'

/**
 * Determine whether or not profile is authenticated
 * @param authenticated
 * @param authenticationDate
 * @returns {*|boolean}
 */
export const getIsAuthenticated = (authenticated, authenticationDate) => {
  if (!authenticated || !authenticationDate) {
    return false
  }

  const timeOfLogin = moment(authenticationDate)
  const timeOfExpiry = timeOfLogin.add(1, 'day')
  const timeNow = moment()

  return timeNow.isBefore(timeOfExpiry)
}
