import { UPDATE_SEARCH_QUERY, CLEAR_SEARCH_QUERY } from '../../actions'

const initialState = {
  query: ''
}

const search = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_QUERY:
      return {
        ...state,
        query: action.payload
      }
    case CLEAR_SEARCH_QUERY:
      return initialState
    default:
      return state
  }
}

export default search
