import {
  GET_TOPIC_ITEM_REQUEST,
  GET_TOPIC_ITEM_SUCCESS,
  GET_TOPIC_ITEM_FAILURE,
  SUBSCRIBE_TOPIC_REQUEST,
  SUBSCRIBE_TOPIC_SUCCESS,
  SUBSCRIBE_TOPIC_FAILURE,
  UNSUBSCRIBE_TOPIC_REQUEST,
  UNSUBSCRIBE_TOPIC_SUCCESS,
  UNSUBSCRIBE_TOPIC_FAILURE,
  TOPIC_SUBCOUNT_REQUEST,
  TOPIC_SUBCOUNT_SUCCESS,
  TOPIC_SUBCOUNT_FAILURE,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/index'

const initialState = {
  data: {},
  status: IDLE
}

const topicItem = (state = initialState, action) => {
  let data = action.payload && action.payload.data

  switch (action.type) {
    case GET_TOPIC_ITEM_REQUEST:
    case SUBSCRIBE_TOPIC_REQUEST:
    case UNSUBSCRIBE_TOPIC_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_TOPIC_ITEM_SUCCESS:
    case SUBSCRIBE_TOPIC_SUCCESS:
    case UNSUBSCRIBE_TOPIC_SUCCESS:
      return {
        ...state,
        data,
        status: FETCHED
      }
    case GET_TOPIC_ITEM_FAILURE:
    case SUBSCRIBE_TOPIC_FAILURE:
    case UNSUBSCRIBE_TOPIC_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    case TOPIC_SUBCOUNT_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case TOPIC_SUBCOUNT_SUCCESS:
      return {
        ...state,
        data: {...state.data, subs_count: data.count || 0},
        status: FETCHED
      }
    case TOPIC_SUBCOUNT_FAILURE:
      return {
        ...state,
        count: 0,
        status: FAILED
      }
    default:
      return state
  }
}

export default topicItem
