import React, { PureComponent } from 'react'
import TattleLink from '../../components/link/link.component'
import PropTypes from 'prop-types'
import { getClassName } from '../../utils'
import './button.component.css'

/**
 * Button Component
 */
class Button extends PureComponent {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  /**
   * Handle start of Button hiding process
   * This is done to provide a fadeout animation
   */
  handleClick (event) {
    const { onClick } = this.props

    event.target && event.target.blur()
    onClick && onClick(event)
  }

  /**
   * Render Button Component
   * @returns {*}
   */
  render () {
    const {
      className, reference, linkTo, linkTarget, styles, label,
      icon, block, bordered, notAvailable,
      theme, tall, wide, circle, square,
      loading, invisible, hidden, disabled, tooltip,
	    image, secondaryIcon, iconType
    } = this.props

    const baseClassName = getClassName('button', [
      { condition: className, trueClassName: className },
      { condition: theme, trueClassName: `button--${theme}` },
      { condition: tall, trueClassName: 'button--tall' },
      { condition: wide, trueClassName: 'button--wide' },
      { condition: circle, trueClassName: 'button--circle' },
      { condition: square || (!label && icon), trueClassName: 'button--square' },
      { condition: loading, trueClassName: 'button--loading' },
      { condition: invisible, trueClassName: 'button--invisible' },
      { condition: hidden, trueClassName: 'button--hidden' },
      { condition: disabled, trueClassName: 'button--disabled' },
      { condition: notAvailable, trueClassName: 'button--not-available' },
      { condition: block, trueClassName: 'button--block'},
      { condition: bordered, trueClassName: 'button--bordered'}
    ])

    return linkTo ? (
      <TattleLink className={baseClassName} to={linkTo} target={linkTarget} style={styles && styles.base}
                  onClick={this.handleClick} ref={reference}>

	      {icon && iconType === 'material' ?  <span className="material-icons" style={styles && styles.icon}>{icon}</span> : <i className={'icon-' + icon} style={styles && styles.icon} />}
				{image && <img src={image} alt='' className='image-icon' />}
        {label && <span className='button_label' style={styles && styles.label}>{label}</span>}
				{secondaryIcon && <i className={'secondary-icon icon-' + secondaryIcon} style={styles && styles.icon} />}
        {tooltip && <div className='tooltip tooltip-bottom'>{tooltip}</div>}
      </TattleLink>
    ) : (
      <button className={baseClassName} style={styles && styles.base} onClick={this.handleClick} ref={reference}
              disabled={disabled}>
				{icon && iconType === 'material' ?  <span className="material-icons" style={styles && styles.icon}>{icon}</span> : <i className={'icon-' + icon} style={styles && styles.icon} />}
				{image && <img src={image} alt='' className='image-icon' />}
        {label && <span className='button_label' style={styles && styles.label}>{label}</span>}
	      {secondaryIcon && <i className={'secondary-icon icon-' + secondaryIcon} style={styles && styles.icon} />}
        {tooltip && <div className='tooltip tooltip-bottom'>{tooltip}</div>}
      </button>
    )
  }
}

Button.propTypes = {
  /**
   * A custom className to add to the component
   */
  className: PropTypes.string,

  /**
   * Used to determine the ref attribute of the returned element
   */
  reference: PropTypes.object,

  /**
   * Used to transform the button into a Link component
   * Note: prop provides link url
   */
  linkTo: PropTypes.string,

  /**
   * Custom styles passed into the component
   */
  styles: PropTypes.shape({
    base: PropTypes.object,
    icon: PropTypes.object,
    label: PropTypes.object
  }),

  /**
   * Used to provide the button with an icon
   */
  icon: PropTypes.string,

  /**
   * Used to provide the button with an icon as an image
	 * will expect an url as a string
   */
  image: PropTypes.string,

  /**
   * Used to provide the button with a label
   */
  label: PropTypes.string,

  /**
   * Used to paint the Button using a specific theme
   */
  theme: PropTypes.string,

  /**
   * Used to modify the Button component with various modifiers
   */
  tall: PropTypes.bool,
  square: PropTypes.bool,
  circle: PropTypes.bool,
  wide: PropTypes.bool,
  bordered: PropTypes.bool,

  /**
   * Used to paint the Button component with a pulsing loader
   */
  loading: PropTypes.bool,

  /**
   * Used to hide the Button component with opacity
   */
  invisible: PropTypes.bool,

  /**
   * Used to hide the Button component with display: none
   */
  hidden: PropTypes.bool,

  /**
   * Used to disable interaction with the Button component
   */
  disabled: PropTypes.bool,

  /**
   * A callback for when the user clicks the Button component
   */
  onClick: PropTypes.func,

  notAvailable: PropTypes.bool
}

Button.defaultProps = {
  linkTo: null,
  tall: false
}

export default Button
