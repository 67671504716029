import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../utils/ui/ui.util'
import './viewSwitch.component.css'

/**
 * ViewSwitch Component
 */
class ViewSwitch extends PureComponent {
  constructor (props) {
    super(props)

    const activeViewIndex = (props.views || []).findIndex((view) => view.id === props.activeViewId)

    this.state = {
      mountedIndexes: typeof activeViewIndex !== 'undefined' ? [activeViewIndex] : []
    }

    this.handleViewChange = this.handleViewChange.bind(this)
    this.renderViews = this.renderViews.bind(this)
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { activeViewId } = this.props

    if (activeViewId !== prevProps.activeViewId) {
      this.handleViewChange(activeViewId)
    }
  };

  /**
   * Handle view change
   * @param newActiveViewId
   */
  handleViewChange (newActiveViewId) {
    const { views, rememberMounted } = this.props
    const { mountedIndexes } = this.state

    const newActiveViewIndex = views.findIndex((view) => view.id === newActiveViewId)

    if (rememberMounted && mountedIndexes.indexOf(newActiveViewIndex) < 0) {
      this.setState({
        mountedIndexes: [...mountedIndexes, newActiveViewIndex]
      })
    }
  }

  /**
   * Render View Items
   * @param views
   * @param activeViewId
   * @returns {*}
   */
  renderViews (views, activeViewId) {
    const { rememberMounted } = this.props
    const { mountedIndexes } = this.state

    if (rememberMounted) {
      return views.map((view, index) => {
        if (rememberMounted && mountedIndexes.indexOf(index) < 0) {
          return null
        }

        const viewClassName = getClassName('view-switch_item', [
          { condition: view.id === activeViewId, trueClassName: 'view-switch_item--active' }
        ])

        return (
          <div key={`view-switch_item--${view.id}`} className={viewClassName}>
            {typeof view.component === 'function' ? view.component() : view.component}
          </div>
        )
      })
    } else {
      const activeView = views.find((view, index) => view.id === activeViewId)

      return (
        <div className='view-switch_item view-switch_item--standalone'>
          {typeof activeView.component === 'function' ? activeView.component() : activeView.component}
        </div>
      )
    }
  }

  /**
   * Render range tab
   * @returns {*}
   */
  render () {
    const { views, activeViewId } = this.props

    if (!views || !views.length || !activeViewId) {
      return null
    }

    return (
      <section className='view-switch'>
        {this.renderViews(views, activeViewId)}
      </section>
    )
  }
}

ViewSwitch.propTypes = {
  /**
   * The tabs to render in the ViewSwitch component
   */
  views: PropTypes.arrayOf(PropTypes.shape({
    /**
     * The unique identification string for the tab
     */
    id: PropTypes.string.isRequired,

    /**
     * Used to determine what to render below the ViewSwitch component when the corresponding tab is active
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
  })).isRequired,

  /**
   * Used to determine the initially active view by view.id
   */
  activeViewId: PropTypes.string.isRequired,

  /**
   * Used to determine whether or not ViewSwitch remembers which child components have been previously mounted
   * Use this if you don't want to unmount the inactive view after it has changed
   */
  rememberMounted: PropTypes.bool
}

ViewSwitch.defaultProps = {
  rememberMounted: true
}

export default ViewSwitch
