import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { loginProfile, clearLoginStatus, registerProfile, getSubscriptions, sendForgotPasswordEmail } from '../../actions'
import { SEND_FORGOT_PASSWORD_EMAIL_SUCCESS, SEND_FORGOT_PASSWORD_EMAIL_FAILURE } from '../../actions'
import { AuthenticationComponent } from '../../components'

import './authentication.container.css'
import { Snackbar } from '@mui/material'

class AuthenticationContainer extends PureComponent {
  constructor (props) {
    super(props)

    if(props.redirect) {
      this.state = {
        redirect: props.redirect,
        toastOpen: false,
        toastLabel: ''
      }
    }else{
      this.state = {
        toastOpen: false,
        toastLabel: ''
      }
    }

    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.handleRegisterClick = this.handleRegisterClick.bind(this)
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this)
    this.clearErrorStatus = this.clearErrorStatus.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { profile, auth, getSubscriptions } = this.props

    // todo : see if there is a better way
    if (profile.authenticated) {
      auth.handleLogin(profile.token)
      getSubscriptions(profile.user._id, {token: profile.token.access_token})
    }
  }

  clearErrorStatus () {
    this.props.clearLoginStatus()
  }

  handleLoginClick (form) {
    const { loginProfile, onClose } = this.props

    loginProfile(form).then((data) => {
      if (data.type === 'LOGIN_SUCCESS') {
        if(this.state.redirect) {
          window.location = this.state.redirect
        }else{
          window.location = '/home'
        }
        onClose()
      }
    })
  }

  handleRegisterClick (form) {
    const { registerProfile, onClose } = this.props

    form.username = form.email

    registerProfile(form).then((data) => {
      if (data.type === 'REGISTER_SUCCESS') {
        if(this.state.redirect) {
          window.location = this.state.redirect
        }
        onClose()
      }
    })
  }

  handleForgotPasswordClick (form) {
    const { sendForgotPasswordEmail, onClose } = this.props
    sendForgotPasswordEmail(form.email).then((data) => {
      onClose();
      if (data.type === SEND_FORGOT_PASSWORD_EMAIL_SUCCESS) {
        this.setState({
          toastOpen: true,
          toastLabel: 'If this email exists, we will send you a link to reset your paassword.'
        })
      }else if (data.type === SEND_FORGOT_PASSWORD_EMAIL_FAILURE) {
        this.setState({
          toastOpen: true,
          toastLabel: data.payload.response?.message || 'Failed to send email'
        })
      }
    })
  }

  render () {
    const { profile, loginFormVisible, onClose, formType } = this.props

    return (
      <Fragment>
        <div className='auth-container'>
          {loginFormVisible
            ? <AuthenticationComponent
              isVisible={loginFormVisible}
              onHide={onClose}
              onLoginClick={this.handleLoginClick}
              onRegisterClick={this.handleRegisterClick}
              onForgotPasswordClick={this.handleForgotPasswordClick}
              onClearErrorStatus={this.clearErrorStatus}
              profile={profile}
              formType={formType}
            />
            : ''}
        </div>
        <Snackbar 
            anchorOrigin ={{vertical: "top", horizontal: "center"}}  
            open={this.state.toastOpen} 
            autoHideDuration={10000} 
            onClose={() => {
              this.setState({ toastOpen: false })
            }} 
            message={this.state.toastLabel} />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { profile } = state
  const { authenticated, authenticationDate } = profile

  return {
    profile,
    authenticated,
    authenticationDate
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginProfile: (body) => dispatch(loginProfile(body)),
  clearLoginStatus: () => dispatch(clearLoginStatus()),
  registerProfile: (body) => dispatch(registerProfile(body)),
  sendForgotPasswordEmail: (email) => dispatch(sendForgotPasswordEmail(email)),
  getSubscriptions: (profileId, auth) => dispatch(getSubscriptions(profileId, auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer)
