import React from 'react'
import PropTypes from 'prop-types'
import { Button, Field, TattleLink } from '../../index'
import '../authentication.component.css'
import { ValidateForgotPasswordForm } from '../../../utils'
/**
 * Login Form component
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ForgotPasswordForm = (props) => {
    const [email, setEmail] = React.useState('')
    const [isValid, setIsValid] = React.useState(true)
    const [message, setMessage] = React.useState('')

    const {
        handleViewSwitch,
        onForgotPasswordClick
    } = props

    const handleForgotPasswordClick = () => {
        const meta = ValidateForgotPasswordForm(email)

        if (meta.state) {
            setIsValid(false)
            setMessage(meta.message)
            return;
        }else {
            setIsValid(true)
            setMessage('')
        }

        onForgotPasswordClick && onForgotPasswordClick({ email })
    }


    return (
        <section className='login-modal_inner'>
        <h3 className='login-modal_title'>Forgot Password</h3>
        <div className='login-modal_disclaimer'>By continuing, you agree to our <TattleLink to="/termsofservice">Terms of Service</TattleLink> and <TattleLink to="privacypolicy">Privacy Policy</TattleLink></div>
        {!isValid ? (<p className={'error_message'}>{message}</p>) : ('')}
        <div className='login-modal_form'>
            <Field
            size='full'
            id='login_email'
            type='email'
            name='email'
            forceValue={email}
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
            />
            <Button
            label='Request password reset'
            onClick={() => handleForgotPasswordClick()}
            />

        </div>

        <div className='login-modal_message'>
            <span>New to Tattle? </span>
            <span className='link' onClick={() => handleViewSwitch('registration')}>Sign up</span>
            <br/>
            <span>Already have an account? </span>
            <span className='link' onClick={() => handleViewSwitch('login')}>Sign In</span>
        </div>
        </section>
    )
}

ForgotPasswordForm.propTypes = {
    onLoginClick: PropTypes.func.isRequired,
    handleLoginFieldChange: PropTypes.func.isRequired,
    handleViewSwitch: PropTypes.func.isRequired
}

export default ForgotPasswordForm
