import 'babel-polyfill'
import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import configureStore from './store/config.store'
import { PersistGate } from 'redux-persist/integration/react'
import RootContainer from './containers/root/root.container'

async function init () {
  const { store, persistor, history } = await configureStore()


  const root = createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootContainer history={history} />
      </PersistGate>
    </Provider> 
  )
}

init()