import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../config'
import { POST } from '../index'

/**
 * Feedback Action Type Constants
 * @type {string}
 */
export const POST_FEEDBACK_REQUEST = 'POST_FEEDBACK_REQUEST'
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS'
export const POST_FEEDBACK_FAILURE = 'POST_FEEDBACK_FAILURE'

/**
 * Dispatch post feedback action
 */
export const sendFeedback = (auth, feedback, timestamp, userEmail, userAgent) => {
  let headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.getToken()}` } }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/feedback`,
      method: POST,
      headers,
      body: JSON.stringify({
        feedback,
        timestamp,
        userEmail,
        userAgent
      }),
      types: [
        POST_FEEDBACK_REQUEST,
        POST_FEEDBACK_SUCCESS,
        POST_FEEDBACK_FAILURE
      ]
    }
  })
}
