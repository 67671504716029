import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Title, LoadingMask, TattleLink, EmailVerification} from '../../components'
import {FETCHING, logoutProfile, clearSearchQuery} from '../../actions'
import './setting.page.css'

class SettingPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}

        this.onLogoutClick = this.onLogoutClick.bind(this)
    }

   onLogoutClick () {
        const { logoutProfile, auth } = this.props

        logoutProfile()
        auth.handleLogout()
    }

    render() {
        const {fetchingStatus, user} = this.props
        const avatar_letter = (user && user.display_name && user.display_name[0]) || '?'

        return (
            <div className='setting-page'>
                {fetchingStatus === FETCHING ? (
                    <LoadingMask
                    />
                ) : (
                    <Title className={'centered'}>Settings</Title>
                )}
                <div className='setting-page_inner'>
                    <div className='profile-row'>
                        <div className='profile-row_avatar'>
                            <div className='avatar'>{avatar_letter}</div>
                        </div>
                        <TattleLink
                            to={'/settings/account'}>
                            <div className='profile-row_information'>
                                <div className='information'>
                                    <div className='display_name'>{user.display_name}</div>
                                    <div className='account-label'>Account Details</div>
                                </div>
                                <div className='more-arrow'>
                                    <i className='icon icon-arrow_forward'/>
                                </div>
                            </div>
                        </TattleLink>
                    </div>
                    <ul className='settings-list'>
                        <TattleLink
                            to={'/settings/membership'}
                            className='settings-link'
                        >
                            <li>
                                <div>Membership</div>
                                <span>Manage your membership & billing</span>
                                <div className='more-arrow'>
                                    <i className='icon icon-arrow_forward'/>
                                </div>
                            </li>
                        </TattleLink>
                    </ul>
                    <ul className='settings-list'>
                        <TattleLink
                            to={'/settings/help'}
                            className='settings-link'
                        >
                            <li>
                                <div>Help & Legal</div>
                                <span>Request support & review policies</span>
                                <div className='more-arrow'>
                                    <i className='icon icon-arrow_forward'/>
                                </div>
                            </li>
                        </TattleLink>
                    </ul>
                </div>
                <EmailVerification auth={this.props.auth} />
                <div>
                    <TattleLink
                        to={'/trending'}
                        onClick={this.onLogoutClick}
                    >
                        <div className='logout-button'>
                            Sign Out
                        </div>
                    </TattleLink>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {profile} = state

    return {
        user: profile.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    logoutProfile: () => dispatch(logoutProfile()),
    clearSearchQuery: () => dispatch(clearSearchQuery())
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage)
