import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  createBookmark,
  deleteBookmark,
  fetchArticles,
  fetchArticlesByCategoryName,
} from '../../actions'
import { LoadingMask, ArticleList, JumpToTop } from '../../components'
import { logUserActions } from '../../utils'
import './trending.container.css'
import {Tab, Tabs} from "@mui/material";


class TrendingContainer extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isFirstLoad: true,
      isArticleDetailsOpen: false,
      pageSize: 25,
      articles: [],
      isLoading: false,
      listOptions: {
        search: '',
        page: 1,
        offset: 0
      },
      category: 'all'
    }

    this.getArticles = this.getArticles.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.onBookmarkCreate = this.onBookmarkCreate.bind(this)
    this.onBookmarkDelete = this.onBookmarkDelete.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  componentDidMount () {
    this.getArticles({ page: 1, offset: 0, isFirstLoad: true }, true)
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  componentDidUpdate (prevProps) {
    const { searchQuery, profile } = this.props

    if (searchQuery !== prevProps.searchQuery) {
      if (searchQuery) {
        logUserActions('search', searchQuery, profile.user)
      }

      this.setState({
        listOptions: {
          search: searchQuery,
          page: 1,
          offset: 0
        }
      })

      this.getArticles({ search: searchQuery, page: 1, offset: 0 }, !searchQuery)
    }
  }

  getArticles (query, isFirstLoad) {
    const { fetchArticlesByCategoryName } = this.props
    const { listOptions, category } = this.state

    if (!query.category) {
      query.category = category
    }

    query = { ...listOptions, ...query }

    if (query.search && query.page === 1) {
      isFirstLoad = true
    }

    fetchArticlesByCategoryName(query, isFirstLoad).then(() => {
      const newState = {
        isLoading: false
      }

      if (isFirstLoad) {
        newState.isFirstLoad = false
      }

      this.setState(newState)
    })
  }

  handleScroll (event) {
    const { listOptions, pageSize, isLoading, category } = this.state

    if (event.target.scrollHeight - event.target.scrollTop - event.target.offsetHeight < 2000 &&
      !isLoading && event.target.className !== 'subscriptions') {
      const offset = listOptions.page * pageSize
      const page = listOptions.page + 1
      const search = listOptions.search

      this.setState({
        isLoading: true,
        listOptions: {
          search,
          page,
          offset
        }
      })

      this.getArticles({ page, offset, search, category })
    }
  }

  onBookmarkCreate (body) {
    const { auth, createBookmark } = this.props

    if (auth.isLoggedIn()) {
      const { listOptions } = this.state
      const params = {
        isFirstLoad: true,
        token: auth.getToken()
      }

      createBookmark(body, params).then(() => {
        this.getArticles({ search: listOptions.searchQuery, page: 1, offset: 0, isFirstLoad: true }, params)
      })
    }
  }

  onBookmarkDelete (id) {
    const { auth, deleteBookmark } = this.props

    if (auth.isLoggedIn()) {
      const { listOptions } = this.state
      const params = {
        isFirstLoad: true,
        token: auth.getToken()
      }

      deleteBookmark(id, params).then(() => {
        this.getArticles({ search: listOptions.searchQuery, page: 1, offset: 0, isFirstLoad: true }, params)
      })
    }
  }

  handleTabChange(event, value) {
    const { listOptions } = this.state

    this.setState({
      category: value,
      listOptions: {
        page: 1,
        offset: 0,
        search: listOptions.search
      }
    })

    const options = {
      search: listOptions.search,
      page: 1,
      offset: 0,
      category: value,
      isFirstLoad: true
    }

    this.getArticles(options, true)
  }

  render () {
    const { articles, profile, auth } = this.props
    const { isFirstLoad } = this.state

    return (
      <div className='trending-container'>
        {isFirstLoad ? (
          <LoadingMask
          />
        ) : (
            <>
              <div className={'trending-container_navigation'}>
                <Tabs
                    value={this.state.category}
                    onChange={this.handleTabChange}
                    aria-label="trending page tabs"
                    className={'navigation_tabs'}
                >
                  <Tab label="All" value={"all"} />
                  <Tab label="Gaming" value={"gaming"} />
                  <Tab label="Sports" value={"sports"} />
                  <Tab label="Tech" value={"technology"} />
                  <Tab label="Finance" value={"finance"} />
                  <Tab label="Entertainment" value={"entertainment"} />
                  <Tab label="Sources" value={"sources"} />
                </Tabs>
              </div>
              <ArticleList
                articles={articles}
                includeSectionTitle
                title={'Trending'}
                profile={profile}
                auth={auth}
                createBookmark={this.onBookmarkCreate}
                removeBookmark={this.onBookmarkDelete}
              />
               <JumpToTop/>
            </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { search, profile, article } = state
  const { authenticated, authenticationDate } = profile

  return {
    authenticated,
    authenticationDate,
    articles: article.list.data || [],
    searchQuery: search.query,
    profile: profile
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchArticles: (query, auth) => dispatch(fetchArticles(query, auth)),
  fetchArticlesByCategoryName: (catName, isFirstLoad) => dispatch(fetchArticlesByCategoryName(catName, isFirstLoad)),
  createBookmark: (body, auth) => dispatch(createBookmark(body, auth)),
  deleteBookmark: (id, auth) => dispatch(deleteBookmark(id, auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(TrendingContainer)
