import React from 'react'
import PropTypes from 'prop-types'
import TattleLink from '../link/link.component'
import './subscriptions.component.css'


/**
 * Subscriptions list component
 * @param props
 * @constructor
 */
const SubscriptionsList = (props) => {
  const { subscriptions, onSubscriptionClick } = props
  const noData = !subscriptions || !subscriptions.length
  const scrollClass = (subscriptions.length > 3) ? 'subscriptions--scroll' : ''

  return (
    <section className={`subscriptions ${scrollClass}`}>
      <ul className='subscriptions_list'>
        {!noData ? subscriptions.map((subscription) => {
          const { _id, _topic } = subscription
          const { icon, label, seo_name } = _topic

          return (
              <li key={`subscription_item--${_id}`} className='subscription_item'>
                <TattleLink className='subscription_link' to={`/topic/${seo_name}`} onClick={() => onSubscriptionClick()}>
                  <img
                    alt={label}
                    className='subscription_thumbnail'
                    src={icon}
                  />
                  <label className='subscription_tooltip'>{label}</label>
                </TattleLink>
              </li>
            )
        }) : (
          <li className='subscription_item subscription_item--no-data'>No subscriptions available.</li>
        )}
      </ul>
    </section>
  )
}

SubscriptionsList.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    _topic: PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      seo_name: PropTypes.string
    })
  })),
  onSubscriptionClick: PropTypes.func
}

SubscriptionsList.defaultProps = {
  subscriptions: []
}

export default SubscriptionsList