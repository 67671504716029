import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { verifyEmail, clearEmailVerification } from '../../actions'
import "./verifyEmail.page.css"
import { LoadingMask } from '../../components'

class VerifyEmail extends PureComponent {
	componentDidMount () {
		const params = new URL(window.location.toString()).searchParams;
		const email = params.get("email");
		const verificationToken = params.get("verificationToken");
		this.props.verifyEmail(email, verificationToken)
	}

	componentDidUpdate () {
		if (this.props.verifyEmailStatus === "FETCHED" || this.props.verifyEmailStatus === "FAILED") {
			// wait 3 seconds then redirect
			setTimeout(() => {
				this.props.clearEmailVerification()
				window.location = "/"
			}, 3000)
		}
	}

	render () {
		if (this.props.verifyEmailStatus === "FETCHED") {
			return <div className="verify-email_inner">
					<span>Success! </span>
					<br/><br/>
					<span>You have successfully verified your email. Redirecting you in 3 seconds...</span>
				</div>
		}

		if (this.props.verifyEmailStatus === "FAILED") {
			return <div className="verify-email_inner">
					<span>This link is invalid or expired, please request a new verification email.</span>
					<br/><br/>
					<span>Redirecting you in 3 seconds...</span>
				</div>
		}

		return <LoadingMask />
	}
}

const mapStateToProps = (state) => {
	const { profile } = state;
	const { verifyEmail: { status: verifyEmailStatus, error: verifyEmailError } } = profile;
	return {
		verifyEmailStatus,
		verifyEmailError
	}
}

const mapDispatchToProps = (dispatch) => ({
	verifyEmail: (email, verificationToken) => dispatch(verifyEmail(email, verificationToken)),
	clearEmailVerification: () => dispatch(clearEmailVerification())
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)