import React, { Component } from 'react';

class AdBanner extends Component {

	componentDidMount() {
		(window.adsbygoogle = window.adsbygoogle || []).push({})
	}

	render() {
		const style = {
			display: 'block',
			height: '290px'
		};

		return(
			<ins className="adsbygoogle"
					 style={style}
					 data-ad-client="ca-pub-7307961427110230"
					 data-ad-slot={this.props.slot}
					 data-full-width-responsive="true"
					 data-ad-format="">
			</ins>
		);
	}
}

export default AdBanner;