import React, { PureComponent } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import './link.component.css'

/**
 * Link component to handle both internal and external links
 *
 */
class TattleLink extends PureComponent {
  static isInternal (to) {
    return (to.indexOf('://') === -1)
  }

  render () {
    let { to, children, className, target, ...rest } = this.props
    const isInternal = TattleLink.isInternal(to)

    className = `tattle-link${className? ` ${className}`: ''}`
    target = target || '_blank'
    to = decodeURI(to)

    if (isInternal) {
      return (
        <ReactLink className={className} to={to} {...rest}>{children}</ReactLink>
      )
    } else {
      return (
        <a href={to} className={className} target={target} {...rest}>{children}</a>
      )
    }
  }
}

export default TattleLink
