
export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'
export const EXPIRE_TIME = 'expire_token'

const { API_URL, HEADERS } = require('../config')

// let tokenRenewalTimeout
const localStorage = window.localStorage
// const fetch = window.fetch

export default class AuthService {
  constructor ({ onTokenChange }) {
    const isLoggedIn = this.isLoggedIn()
    const accessToken = localStorage.getItem(ACCESS_TOKEN)

    this.onTokenChange = onTokenChange || (() => {})
		this.tokenRenewalTimeout = null
    this._scheduleRenewal()

    if (isLoggedIn && accessToken) {
      this.onTokenChange(accessToken)
    }
  }

  _checkStatus (response) {
    if (response && response.status !== 200) {
      return Promise.reject(response)
    }
    return response.json()
  }

  _scheduleRenewal () {
    const expiresAt = localStorage.getItem(EXPIRE_TIME)
    const delay = (expiresAt - Date.now()) - 60 * 1000 // update refresh token 1 minute before it expires
    const MAX_SAFE_INT = 2147483647
    //if the delay is longer then 24 days the setTimeout will fail so we will set an intrim timeout
    if (delay > 0 && this.tokenRenewalTimeout === null && delay < MAX_SAFE_INT) {
      this.tokenRenewalTimeout = setTimeout(this.refreshToken, delay, localStorage.getItem(REFRESH_TOKEN))
    }else if(delay > MAX_SAFE_INT && this.tokenRenewalTimeout === null){
      this.tokenRenewalTimeout = setTimeout(()=>{
        clearTimeout(this.tokenRenewalTimeout)
        this._scheduleRenewal()
      }, MAX_SAFE_INT)
    }
  }

  _setSession (authResult) {
    // set jwt auth response
    localStorage.setItem(ACCESS_TOKEN, authResult[ACCESS_TOKEN])
    localStorage.setItem(REFRESH_TOKEN, authResult[REFRESH_TOKEN])
    localStorage.setItem(EXPIRE_TIME, authResult[EXPIRE_TIME])

    // schedule a token renewal
    this._scheduleRenewal()
  }

  /**
   * Check whether the current time is past the access token's expiry time
   *
   * @returns {boolean}
   */
  isLoggedIn () {
    const expiresAt = localStorage.getItem(EXPIRE_TIME)

    return new Date().valueOf() < parseInt(expiresAt)
  }

  /**
   * Set auth token upon successful login
   *
   * @param token
   */
  handleLogin (token) {
    const accessToken = token && token[ACCESS_TOKEN]
    // set auth credentials
    if (token) {
      this._setSession(token)
    }
    this.onTokenChange(accessToken, token)
  }

  /**
   * Handles the reset required for logout function
   */
  handleLogout () {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(EXPIRE_TIME)
    clearTimeout(this.tokenRenewalTimeout)
    this.tokenRenewalTimeout = null
    this.onTokenChange()
  }

  getToken () {
    return localStorage.getItem(ACCESS_TOKEN)
  }

  refreshToken (refreshToken) {
    const requestOptions = {
      method: 'GET',
      headers: HEADERS
    }
    return fetch(`${API_URL}/auth/refreshToken?token=${refreshToken}`, requestOptions)
      .then(this._checkStatus)
      .then((response) => {
        const data = response && response.data
        if (data) {
          const accessToken = data && data[ACCESS_TOKEN]
          this._setSession(data)
          this.onTokenChange(accessToken)
        }
        return response
      })
      .catch((error) =>{
        this.handleLogout()
      })
  }
}
