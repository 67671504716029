import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Footer, AdBanner } from '../../components'
import { updateSearchQuery, clearSearchQuery } from '../../actions'
import './search.container.css'

import config from '../../config'
import { Search } from '../../components'

class SearchContainer extends PureComponent {
	constructor (props) {
		super(props)

		this.state = {}

		this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this)
		this.handleSearchQueryClear = this.handleSearchQueryClear.bind(this)
	}

	handleSearchQueryChange (value) {
		this.props.updateSearchQuery(value || '')
	}

	handleSearchQueryClear () {
		this.props.clearSearchQuery()
	}

	render () {
		const { searchQuery } = this.props

		return (
			<div className='search-container'>
				<div className='search-container_inner'>
					<div className='search-container_top'>
						<Search
							theme='dark-slate'
							height={45}
							value={searchQuery}
							onQueryChange={this.handleSearchQueryChange}
							onClear={this.handleSearchQueryClear}
						/>
					</div>
					<div className='search-container_ads'>
						<div className='ads-title'>Advertisement</div>
						<AdBanner slot='2101559746' />
					</div>
					<div className='search-container_bottom'>
						<Footer version={config.APP_VERSION} />
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { search } = state

	return {
		searchQuery: search.query
	}
}

const mapDispatchToProps = (dispatch) => ({
	updateSearchQuery: (query) => dispatch(updateSearchQuery(query)),
	clearSearchQuery: () => dispatch(clearSearchQuery())
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)