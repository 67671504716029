import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { LoadingMask, ArticleList, Title } from '../../components'
import { FETCHED, FETCHING, createBookmark, deleteBookmark, fetchSubscribedArticles, fetchSubscribedArticlesByQuery, fetchArticlesByTopic } from '../../actions'
import { Tab, Tabs } from '@mui/material'
import { logUserActions } from '../../utils'
import './home.page.css'

class HomePage extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isFirstLoad: true,
      isArticleDetailsOpen: false,
      pageSize: 25,
      articles: [],
      infiniteScrollLoading: false,
      listOptions: {
        search: '',
        page: 1,
        offset: 0,
        feed_type: 'all'
      },
      tabValue: "all",
      selectedFilter: {
        topicName: null,
        topicId: null
      }
    }

    this.getParams = this.getParams.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.getArticles = this.getArticles.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.onBookmarkCreate = this.onBookmarkCreate.bind(this)
    this.onBookmarkDelete = this.onBookmarkDelete.bind(this)
    this.selectFilter = this.selectTopic.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll, true)
    
    this.getArticles({ page: 1, offset: 0, isFirstLoad: true })
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  componentDidUpdate (prevProps) {
    const { searchQuery, profile } = this.props

    if (searchQuery !== prevProps.searchQuery) {
      if (searchQuery) {
        logUserActions('search', searchQuery, profile.user)
      }

      this.setState({
        listOptions: {
          search: searchQuery,
          page: 1,
          offset: 0
        }
      })

      this.getArticles({ search: searchQuery, page: 1, offset: 0 })
    }
  }

  getParams() {
    /**
     * Returns additional params for fetching articles
     * isFirstLoad: boolean, true if loading the first page of articles
     * token: string, auth bearer token
    **/
    const { searchQuery, auth } = this.props
    const params = {
      isFirstLoad: !searchQuery
    }
    
    if(auth.isLoggedIn()){
      params.token = auth.getToken()
    }

    return params;
  }

  getArticlesByTopic (topicId, query) {
    const { listOptions } = this.state
    const { fetchArticlesByTopic } = this.props

    const params = this.getParams()

    query = { ...listOptions, ...query }

    fetchArticlesByTopic(topicId, query, params).then(() => {
      const newState = {
        infiniteScrollLoading: false
      }

      if (query.isFirstLoad) {
        newState.isFirstLoad = false
      }

      this.setState(newState)
    })
  }

  getArticles (query) {
    if(this.state.selectedFilter.topicId != null) {
      this.getArticlesByTopic(this.state.selectedFilter.topicId, query)
      return
    }

    const { fetchSubscribedArticles, fetchSubscribedArticlesByQuery } = this.props
    const { listOptions } = this.state
    let method

    query = { ...listOptions, ...query }

    if (query.isFirstLoad) {
      method = fetchSubscribedArticlesByQuery
    } else if (!query.search) {
      method = fetchSubscribedArticles
    } else {
      method = fetchSubscribedArticlesByQuery
    }

    const params = this.getParams();
    method(query, params).then(() => {
      const newState = {
        infiniteScrollLoading: false
      }

      if (query.isFirstLoad) {
        newState.isFirstLoad = false
      }

      this.setState(newState)
    })
  }

  handleScroll (event) {
    if(event.target.className !== "root-container_inner"){
      return;
    }
    
    const { listOptions, pageSize, infiniteScrollLoading } = this.state

    if (event.target.scrollHeight - event.target.scrollTop - event.target.offsetHeight < 2000 &&
      !infiniteScrollLoading && event.target.className !== 'subscriptions') {
      const offset = listOptions.page * pageSize
      const page = listOptions.page + 1
      const search = listOptions.search
      const feed_type = listOptions.feed_type

      this.setState({
        infiniteScrollLoading: true,
        listOptions: {
          search,
          page,
          offset,
          isFirstLoad:false
        }
      })

      this.getArticles({ page, offset, search, feed_type })
    }
  }

  handleTabChange(value) {
    const { listOptions } = this.state

    this.setState({
      tabValue: value,
      listOptions: {
        feed_type: value,
        page: 1,
        offset: 0,
        search: listOptions.search
      }
    })

    const options = {
      search: listOptions.search,
      page: 1,
      offset: 0,
      feed_type: value,
      isFirstLoad: true
    }

    this.getArticles(options)
  }

  onBookmarkCreate (body) {
    const { createBookmark } = this.props
    const { listOptions } = this.state
    const params = this.getParams()

    createBookmark(body, params).then(() => {
      this.getArticles({ search: listOptions.searchQuery, page: 1, offset: 0, isFirstLoad: true })
    })
  }

  onBookmarkDelete (id) {
    const { deleteBookmark } = this.props
    const { listOptions } = this.state
    const params = this.getParams()

    deleteBookmark(id, params).then(() => {
      this.getArticles({ search: listOptions.searchQuery, page: 1, offset: 0, isFirstLoad: true })
    })
  }

  selectTopic (topicId, topicName) {
    const { selectedFilter: { topicId: selectedTopicId } } = this.state
    
    if(topicId === selectedTopicId) {
      this.setState({ selectedFilter: { topicId: null, topicName: null }}, () => {
        this.getArticles({ page: 1, offset: 0, isFirstLoad: true })
      })
    } else {
      this.setState({ selectedFilter: { topicId, topicName } }, () => {
        this.getArticles({ page: 1, offset: 0, isFirstLoad: true })
      })
    }
  }

  render () {
    const { isFirstLoad } = this.state
    const { articleList: { articles, status: articleStatus }, profile, auth, subscription } = this.props

    return (
      <div className={'home-page'}>
        {subscription && subscription.status === FETCHED && subscription.data.length > 0 ? 
          <div className={'home-page_filter'}>
              {
                subscription.data.map((sub) => {
                  const selected = sub._topic.seo_name === this.state.selectedFilter.topicName
                  return (
                    <div className={`home-page_filter_thumbnail_container ${selected? 'selected':''}`} onClick={() => this.selectTopic(sub._topic._id, sub._topic.seo_name)} key={sub._topic.seo_name}>
                      <img className='home-page_filter_thumbnail_img' src={sub._topic.icon} alt={sub._topic.label} />
                    </div>
                  )
                })
              }
          </div>
          :
          null
        }
        <div className={'home-page_navigation'}>
          <Tabs
            value={this.state.tabValue}
            onChange={(event, newValue) => this.handleTabChange(newValue)}
            aria-label="home page tabs"
            className={'navigation_tabs'}
          >
            <Tab label="All" value={"all"} />
            <Tab label="Articles" value={"articles"} />
            <Tab label="Videos" value={"videos"} />
          </Tabs>
        </div>
        <Title className={'home-page_title'}>
          Home
        </Title>
        {
          isFirstLoad || (articleStatus === FETCHING && !this.state.infiniteScrollLoading)
          // Show loading mask on first load or when switching tabs, but not for infinite scroll
            ? <LoadingMask />
            : null
        }
        {!isFirstLoad && (
          <ArticleList
            articles={articles}
            profile={profile}
            includeSectionTitle={false}
            auth={auth}
            createBookmark={this.onBookmarkCreate}
            removeBookmark={this.onBookmarkDelete}
          />
        )}

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { search, article, profile, subscription } = state
  const { authenticated } = profile

  return {
    articleList: {
      articles: article.list.data,
      status: article.list.status
    },
    searchQuery: search.query,
    profile,
    authenticated,
    subscription
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchSubscribedArticles: (params, auth) => dispatch(fetchSubscribedArticles(params, auth)),
  fetchSubscribedArticlesByQuery: (params, auth) => dispatch(fetchSubscribedArticlesByQuery(params, auth)),
  fetchArticlesByTopic: (topicId, params, auth) => dispatch(fetchArticlesByTopic(topicId, params, auth)),
  createBookmark: (body, auth) => dispatch(createBookmark(body, auth)),
  deleteBookmark: (id, auth) => dispatch(deleteBookmark(id, auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)