import React, {Fragment, PureComponent} from 'react'
import {connect} from 'react-redux'
import {fetchBookmarks, deleteBookmark} from '../../actions'
import './bookmark.container.css'

import {LoadingMask, ArticleList} from '../../components'

class BookmarkContainer extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            initialFetch: false,
            articles: []
        }

        this.onBookmarkDelete = this.onBookmarkDelete.bind(this)
    }

    componentDidMount() {
        const {auth, fetchBookmarks} = this.props
        const authToken = {
            token: auth.getToken()
        }

        fetchBookmarks({}, authToken).then(() => {
            this.setState({
                initialFetch: true
            })
        })
    }

    onBookmarkDelete(id) {
        const {auth, deleteBookmark, fetchBookmarks} = this.props

        const authToken = {
            token: auth.getToken()
        }

        deleteBookmark(id, authToken).then(() => {
            fetchBookmarks({}, authToken)
        })
    }

    render() {
        const {initialFetch} = this.state
        const {auth, profile, bookmarks} = this.props

        if (!auth || !auth.isLoggedIn()) {
            window.open('/trending', '_self')
            return false
        }

        const articles = bookmarks.map((bookmark) => bookmark.article)

        return (
            <div className="bookmark-container">
                {!initialFetch ? (
                    <LoadingMask/>
                ) : (
                    <Fragment>
                        <ArticleList
                            articles={articles}
                            profile={profile}
                            includeSectionTitle
                            title={'Bookmarks'}
                            showName
                            auth={auth}
                            removeBookmark={this.onBookmarkDelete}
                        />
                    </Fragment>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {profile, bookmark} = state

    return {
        profile,
        bookmarks: bookmark.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchBookmarks: (params, auth) => dispatch(fetchBookmarks(params, auth)),
    deleteBookmark: (id, auth) => dispatch(deleteBookmark(id, auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkContainer)