import React from 'react'
import PropTypes from 'prop-types'
import TattleLink from '../../link/link.component'
// import { Title } from '../../index'
import './topicList.component.css'

/**
 * TopicList component
 * @param props
 * @constructor
 */
const TopicList = (props) => {
  const { topics, onTopicSelect } = props
  const noData = !topics || !topics.length

  return (
    <section className='article'>
			<TattleLink
				className={'btn-back'}
				to={'/explore'}
				>
				<span><i className='icon icon-arrow_back' />Back</span>
			</TattleLink>
      <ul className='topic_list'>
        {!noData ? topics.map((topic) => {
          const { _id, seo_name, label, panel, active } = topic

          let li

          if (active) {
            li = (
              <li key={`topic_item--${_id}`} className='topic_item'>
                <TattleLink className='topic_link' to={`/topic/${seo_name}`}>
                  <img
                    alt={label}
                    className='topic_thumbnail'
                    src={panel}
                    onClick={() => onTopicSelect && onTopicSelect(topic)}
                  />
                  <label className='topic_tooltip'>{label}</label>
                </TattleLink>
              </li>
            )
          } else {
            li = (
              <li key={`topic_item--${_id}`} className='topic_item coming-soon'>
                <img
                  alt={label}
                  className='topic_thumbnail'
                  src={panel}
                />
                <div className='topic_item--shadow' />
                <label className='topic_tooltip'>{label}</label>
              </li>
            )
          }

          return li
        }) : (
          <li className='topic_item topic_item--no-data'>No topics available.</li>
        )}
      </ul>
    </section>
  )
}

TopicList.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    seo_name: PropTypes.string,
    panel: PropTypes.string,
    active: PropTypes.bool
  })),
  includeSectionTitle: PropTypes.bool,
  onTopicSelect: PropTypes.func,
  titleClass: PropTypes.string
}

TopicList.defaultProps = {
  topics: []
}

export default TopicList
