import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { last } from 'lodash'

import {
  fetchTopic,
  fetchArticlesByTopic,
  subscribeTopic,
  unsubscribeTopic,
  getSubscriptions,
  FAILED, createBookmark, deleteBookmark,
  getSubscriptionCount
} from '../../actions'
import InfiniteScroll from "react-infinite-scroller";
import { LoadingMask, ArticleList } from '../../components'
import { TopicItem } from '../../components/topic'
import './timeline.container.css'
import { logUserActions } from '../../utils'
import AuthenticationContainer from '../authentication/authentication.container'

class TimelineContainer extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      initialFetch: false,
      isFirstLoad:true,
      loginFormVisible: false,
      searchTerm: '',
      feed_type: 'all',
      loading: false,
      page: 1,
      offset: 0,
      limit: 25
    }

    this.handleInfiniteOnLoad = this.handleInfiniteOnLoad.bind(this)
    this.onSubscribeClick = this.onSubscribeClick.bind(this)
    this.onUnsubscribeClick = this.onUnsubscribeClick.bind(this)
    this.toggleLoginForm = this.toggleLoginForm.bind(this)
    this.onBookmarkCreate = this.onBookmarkCreate.bind(this)
    this.onBookmarkDelete = this.onBookmarkDelete.bind(this)
    this.getArticles = this.getArticles.bind(this)
    this.getTopicSubscriptionCount = this.getTopicSubscriptionCount.bind(this)
    this.handleFeedTypeChange = this.handleFeedTypeChange.bind(this)
    this.onBackButtonClick = this.onBackButtonClick.bind(this)
  }

  componentDidMount () {
    this.handleInfiniteOnLoad()
  }

  componentWillUnmount () {
    document.title = 'Tattle - A new way to connect to your interests!'
  }

  componentDidUpdate (prevProps) {

    const { searchQuery, profile, topic, auth } = this.props
    const { feed_type } = this.state

    if (searchQuery !== prevProps.searchQuery) {
      if (searchQuery) {
        logUserActions('search', searchQuery, profile.user)
      }

      this.setState({
        searchTerm: searchQuery
      })

      const authParams = {}
      if (auth && auth.isLoggedIn()) {
        authParams.token = auth.getToken()
      }
      const options = {
        search: searchQuery,
        isFirstLoad:true,
        feed_type
      }

      this.getArticles(topic._id, options, authParams)
    }
  }

  toggleLoginForm () {
    this.setState({
      loginFormVisible: !this.state.loginFormVisible
    })
  }

  onSubscribeClick () {
    const { auth, subscribeTopic, getSubscriptions, topic, profile } = this.props
    const { loginFormVisible } = this.state

    if (!auth.isLoggedIn() && !loginFormVisible) {
      this.toggleLoginForm()
    } else {
      const topicId = topic._id
      const profileId = profile.user._id
      const params = { token: auth.getToken() }

      subscribeTopic(topicId, profileId, params).then(() => {
        getSubscriptions(profileId, params)
        this.getTopicSubscriptionCount(topicId)
      })
    }
  }

  onUnsubscribeClick () {
    const { auth, unsubscribeTopic, getSubscriptions, topic, profile } = this.props

    if (auth.isLoggedIn()) {
      const topicId = topic._id
      const profileId = profile.user._id
      const params = { token: auth.getToken() }

      unsubscribeTopic(topicId, profileId, params).then(() => {
        getSubscriptions(profileId, params)
        this.getTopicSubscriptionCount(topicId)
      })
    }
  }

  onBookmarkCreate (body) {
    const { auth, createBookmark, topic } = this.props
    const { searchTerm, feed_type, limit } = this.state

    if (auth.isLoggedIn()) {
      const params = { token: auth.getToken() }
      const options = {
        search: searchTerm,
        feed_type,
        limit: limit,
        isFirstLoad:true
      }

      createBookmark(body, params).then(() => {
        this.getArticles(topic._id, options, params)
      })
    }
  }

  onBookmarkDelete (id) {
    const { auth, deleteBookmark, topic } = this.props
    const { searchTerm, feed_type, limit } = this.state

    if (auth.isLoggedIn()) {
      const params = { token: auth.getToken() }
      const options = {
        search: searchTerm,
        feed_type,
        limit: limit,
        isFirstLoad:true
      }

      deleteBookmark(id, params).then(() => {
        this.getArticles(topic._id, options, params)
      })
    }
  }

  getArticles (id, options, params) {
    const { fetchArticlesByTopic } = this.props

    return fetchArticlesByTopic && fetchArticlesByTopic(id, options, params)
  }

  getTopicSubscriptionCount(id) {
    const { getSubscriptionCount } = this.props

    return getSubscriptionCount && getSubscriptionCount(id)
  }

  handleFeedTypeChange (type) {
    const { auth, topic } = this.props
    const { searchTerm, limit } = this.state
    const options = {
      search: searchTerm,
      limit: limit,
      feed_type: type,
      isFirstLoad: true
    }
    const params = {}

    this.setState({
      feed_type: type
    })

    if (auth.isLoggedIn()) {
      params.token = auth.getToken()
    }

    this.getArticles(topic._id, options, params)
  }

	onBackButtonClick () {
  	const { history } = this.props
		history.back()
	}

  handleInfiniteOnLoad () {
    const { history, fetchTopic, profile, auth } = this.props
    const seoName = last(window.location.pathname.split('/'))
    const {page, offset, limit, searchTerm, feed_type, isFirstLoad} = this.state
    const params = {}
    if (!this.state.loading){

        this.setState({
          loading: true,
          offset: limit * page,
          page: page+1
        })

      if (profile && profile.user) {
        params.id = profile.user._id
      }

      if (auth.isLoggedIn()) {
        params.token = auth.getToken()
      }

      fetchTopic('seo_name', seoName, params).then(() => {
          const { _id, label } = this.props.topic || {}

          if (!_id) {
            return history.push('/explore')
          }

          document.title = `Tattle - ${label}`

          const options = {
            search: searchTerm,
            limit: page * limit,
            offset: offset,
            page:page,
            feed_type: feed_type,
            isFirstLoad: isFirstLoad
          }

          this.getArticles(_id, options, params).then(() => {
            this.setState({
              isFirstLoad: false,
              initialFetch: true,
              loading: false
            })
          })

          this.getTopicSubscriptionCount(_id)
        })
      }
  }

  getScrollParent(){
    return document.getElementsByClassName('root-container_inner')[0]
  }

  render () {
    const { topic, topicStatus, articles, profile, auth } = this.props
    const { initialFetch, loginFormVisible, loading } = this.state
    
    if (topicStatus === FAILED) {
      return null
    }

    const { _id, label, icon, isSubscribed, seo_name, subs_count } = topic || {}

    return (
      <div className='timeline-container'>
       
        {!initialFetch ? (
          <LoadingMask />
        ) : (
          <Fragment>
            <TopicItem
              _id={_id}
              label={label}
              icon={icon}
              seo_name={seo_name}
              subs_count={subs_count}
              onTopicSubscribe={this.onSubscribeClick}
              onTopicUnsubscribe={this.onUnsubscribeClick}
              onFeedTypeChange={this.handleFeedTypeChange}
              onBackButtonClick={this.onBackButtonClick}
              isSubscribed={isSubscribed}
              profile={profile}
            />
            <InfiniteScroll
                    getScrollParent={this.getScrollParent}
                    initialLoad={false}
                    loadMore={this.handleInfiniteOnLoad}
                    hasMore={!loading}
                    threshold={1000}
                    useWindow={false}
                  >
              <ArticleList
                articles={articles}
                profile={profile}
                showName
                auth={auth}
                createBookmark={this.onBookmarkCreate}
                removeBookmark={this.onBookmarkDelete}
              />
            </InfiniteScroll>
          </Fragment>
          
        )}
        <AuthenticationContainer
          loginFormVisible={loginFormVisible}
          onClose={this.toggleLoginForm}
          auth={auth}
        />
        
      </div>
      
    )
  }
}

const mapStateToProps = (state) => {
  const { search, profile, topic, article } = state
  const { authenticated, authenticationDate } = profile

  return {
    authenticated,
    authenticationDate,
    topic: topic.item.data,
    topicStatus: topic.item.status,
    articles: article.list.data || [],
    articleStatus: article.list.status,
    searchQuery: search.query,
    profile: profile
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTopic: (type, id, auth) => dispatch(fetchTopic(type, id, auth)),
  subscribeTopic: (topicId, profileId, auth) => dispatch(subscribeTopic(topicId, profileId, auth)),
  getSubscriptions: (profileId, auth) => dispatch(getSubscriptions(profileId, auth)),
  getSubscriptionCount: (topicId) => dispatch(getSubscriptionCount(topicId)),
  unsubscribeTopic: (topicId, profileId, auth) => dispatch(unsubscribeTopic(topicId, profileId, auth)),
  fetchArticlesByTopic: (topicId, params = { limit: 25 }, auth) => dispatch(fetchArticlesByTopic(topicId, params, auth)),
  createBookmark: (body, auth) => dispatch(createBookmark(body, auth)),
  deleteBookmark: (id, auth) => dispatch(deleteBookmark(id, auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimelineContainer)
