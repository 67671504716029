import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {FETCHING, updateProfile} from "../../../../actions";
import {Button, LoadingMask, Title} from "../../../../components";
import {Field, Form, Formik, ErrorMessage} from "formik";
import './update.account.css'
import CircumIcon from "@klarr-agency/circum-icons-react";

class UpdatePhone extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
        this.onBackButtonClick = this.onBackButtonClick.bind(this)
        this.onSaveClick = this.onSaveClick.bind(this)
    }

    componentDidMount() {
        const { clearErrors, fetchingStatus } = this.props

        if (fetchingStatus === 'FETCHED') {
            clearErrors && clearErrors()
        }

        this.setState({
            firstLoad: true
        })
    }

    onBackButtonClick () {
        const {history} = this.props

        history.back()
    }

    async onSaveClick (fields) {
        const { updateProfile, auth, user } = this.props
        const authToken = {
            token: auth.getToken()
        }

        this.setState({
            firstLoad: false
        })

        return updateProfile && updateProfile(user._id, fields, authToken)
    }

    render() {
        const { fetchingStatus, user } = this.props
        const { firstLoad } = this.state
        const fields = {
            display_name: user.display_name,
            username: user.username,
            phone: user.phone || '',
            currentPass: ''
        }

        return (
            <div className='account-setting-page'>
                    {fetchingStatus === FETCHING ? (
                        <LoadingMask
                        />
                    ) : (
                        <Title className={'centered'}>
                            <Button
                                className="back-button"
                                theme="transparent"
                                icon="arrow_back"
                                onClick={this.onBackButtonClick}
                                label='Change Phone'
                            />
                        </Title>
                    )}
                <div className='account-setting-page_inner'>
                    <Formik
                        initialValues={fields}
                        enableReinitialize
                        validate={values => {
                            const errors = {}
                            var pattern = new RegExp('^[0-9]{5,}$');
                            if (!values.currentPass) {
                                errors.currentPass = 'This field is required'
                            }
                            
                            if(values.phone.length > 0 && !pattern.test(values.phone)){
                                errors.phone = 'Phone can only contain numeric characters'
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {

                            this.onSaveClick(values)
                                .then(() => {
                                    setSubmitting(false);
                                    if (fetchingStatus !== 'FAILED') {
                                        resetForm()
                                    }
                                })
                        }}
                    >  
                    {({ isSubmitting }) => (
                        <Form className='update-form'>
                        
                                <div className='row'>
                                    <label htmlFor='name'>Phone</label>
                                    <Field type="text" name="phone" />
                                    <ErrorMessage name="phone" render={
                                        msg => <div className='icon' alt='test'>
                                                <div class="tooltip"><CircumIcon name="circle_alert" color="red" size="20px" />
                                                <span class="tooltiptext">{msg}</span>
                                                </div></div>
                                        } />
                                    
                                </div>
                                <div className='row'>        
                                    <label htmlFor='password'>Password</label>
                                    <Field type="password" name="currentPass"  />
                                    <ErrorMessage name="currentPass" component="div" className="field-error"  />
                                </div>
                                
                                <div className='row'>
                                    
                                    <button type="submit" disabled={isSubmitting}>
                                        Save
                                    </button>

                                    <div className='blueLink' onClick={this.onBackButtonClick}>Cancel</div>
                                </div>
                        </Form>
                    )}
                    </Formik> 
                    {(fetchingStatus !== 'FAILED' && !firstLoad) ? <div className='form-message'>Updated successfully!</div> : 
                    (!firstLoad)?<div className='form-error'>Updated failed!</div> : ''}
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    const {profile} = state

    return {
        fetchingStatus: profile.status,
        user: profile.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateProfile: (id, body, auth) => dispatch(updateProfile(id, body, auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePhone)