import {
	GET_ARTICLE_DETAIL_REQUEST,
	GET_ARTICLE_DETAIL_SUCCESS,
	GET_ARTICLE_DETAIL_FAILURE,
	IDLE,
	FAILED,
	FETCHED,
	FETCHING
} from '../../../actions'

const initialState = {
	record: {},
	status: IDLE
}

const item = (state = initialState, action) => {
	switch (action.type) {
		case GET_ARTICLE_DETAIL_REQUEST:
			return {
				...state,
				status: FETCHING
			}
		case GET_ARTICLE_DETAIL_SUCCESS:
			return {
				...state,
				record: action.payload.data,
				status: FETCHED
			}

		case GET_ARTICLE_DETAIL_FAILURE:
			return {
				...state,
				status: FAILED
			}
		default:
			return state
	}

}
export default item