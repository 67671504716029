/**
 * Will validate the fields of the registration form
 *
 * @param fields
 * @returns {{state: boolean, message: string}}
 * @constructor
 */
export const ValidateRegistrationForm = (fields) => {
  const {
    email,
    password,
    confirmPassword,
    name
  } = fields

  if (!email || !password || !confirmPassword || !name) {
    return {
      state: true,
      message: 'Missing required fields'
    }
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return {
      state: true,
      message: 'Email address is not valid'
    }
  }

  if (password.length < 6) {
    return {
      state: true,
      message: 'Passwords is too short. Please use password between 6 and 16 characters.'
    }
  }

  if (password.length > 16) {
    return {
      state: true,
      message: 'Passwords is too long. Please use password between 6 and 16 characters.'
    }
  }

  if (password !== confirmPassword) {
    return {
      state: true,
      message: 'Passwords do not match'
    }
  }

  return {
    state: false,
    message: ''
  }
}

export const ValidateForgotPasswordForm = (email) => {
  if (!email) {
    return {
      state: true,
      message: 'Missing required fields'
    }
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return {
      state: true,
      message: 'Email address is not valid'
    }
  }

  return {
    state: false,
    message: ''
  }
}