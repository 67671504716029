import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import './profile.component.css'
import { withStyles } from '@mui/styles'
import { Button } from '../index'

const styles = {
  paper: {
    margin: 0
  }
}

class ProfileComponent extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {}

    this.handleModalClose = this.handleModalClose.bind(this)
  }

  handleModalClose (type) {
    const { onClose, onLogout } = this.props

    onClose && onClose(type)
    if (type === 'logout') {
      onLogout && onLogout()
    }
  }

  render () {
    const { targetEl, onClose, isVisible } = this.props
    const id = Boolean(targetEl) ? 'user-popover' : undefined

    return (
      (targetEl) ?
      <Popover
        id={id}
        open={isVisible}
        anchorEl={targetEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: 'profile-popover' }}
      >
        <ul key='profile-items'>
          <li key='setting'>
            <Button
              theme='transparent'
              linkTo='/settings'
              block
              icon='settings'
              label='Settings'
              // notAvailable
              className={'popover-menu-item'}
              onClick={() => this.handleModalClose('settings')}
            />
          </li>
          <li key='logout'>
            <Button
              theme='transparent'
              linkTo='/trending'
              block
              icon='exit_to_app'
              label='Logout'
              className={'popover-menu-item'}
              onClick={() => this.handleModalClose('logout')}
            />
          </li>
        </ul>
      </Popover>
        : ''
    )
  }
}

ProfileComponent.propTypes = {
  /**
   * Optional Class Name
   */
  className: PropTypes.string
}

export default withStyles(styles)(ProfileComponent)
