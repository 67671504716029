import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import './footer.component.css'
import { TattleLink } from '../index'

/**
 * Footer component
 * @param props
 * @constructor
 */
const Footer = (props) => {
  const { version } = props

  return (
    <div className='footer'>
      <div className='footer_item footer_social-icons'>
        <TattleLink to={'https://www.facebook.com/Tattle/'} className={'social-icons'}>
          <i className='icon-facebook' />
        </TattleLink>
        <TattleLink to={'https://twitter.com/Tattle_news'} className={'social-icons'}>
          <i className='icon-twitter-with-circle' />
        </TattleLink>
      </div>
      <div className='footer_item footer_copy'>
        &copy; Tattle Media, Inc. {moment.utc(new Date()).format('YYYY')} · <span className='version'>Version {version}</span>
      </div>
      <div className='footer_item footer_links'>
        <TattleLink to={'/privacypolicy'} className={'privacy-policy'}>
          <span className='privacy-policy'>Privacy Policy</span>
        </TattleLink>
        <TattleLink to={'/termsofservice'} className={'terms-of-use'}>
          <span className='terms-of-use'>Terms of Use</span>
        </TattleLink>
      </div>
    </div>
  )
}

Footer.propTypes = {
  version: PropTypes.string
}

Footer.defaultProps = {
  version: '0.0.0'
}

export default Footer
