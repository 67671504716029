import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../../config'
import { GET, POST } from '../../index'

/**
 * Topic Action Type Constants
 * @type {string}
 */
export const GET_TOPIC_ITEM_REQUEST = 'GET_TOPIC_ITEM_REQUEST'
export const GET_TOPIC_ITEM_SUCCESS = 'GET_TOPIC_ITEM_SUCCESS'
export const GET_TOPIC_ITEM_FAILURE = 'GET_TOPIC_ITEM_FAILURE'

export const SUBSCRIBE_TOPIC_REQUEST = 'SUBSCRIBE_TOPIC_REQUEST'
export const SUBSCRIBE_TOPIC_SUCCESS = 'SUBSCRIBE_TOPIC_SUCCESS'
export const SUBSCRIBE_TOPIC_FAILURE = 'SUBSCRIBE_TOPIC_FAILURE'

export const UNSUBSCRIBE_TOPIC_REQUEST = 'UNSUBSCRIBE_TOPIC_REQUEST'
export const UNSUBSCRIBE_TOPIC_SUCCESS = 'UNSUBSCRIBE_TOPIC_SUCCESS'
export const UNSUBSCRIBE_TOPIC_FAILURE = 'UNSUBSCRIBE_TOPIC_FAILURE'

export const TOPIC_SUBCOUNT_REQUEST = 'TOPIC_SUBCOUNT_REQUEST'
export const TOPIC_SUBCOUNT_SUCCESS = 'TOPIC_SUBCOUNT_SUCCESS'
export const TOPIC_SUBCOUNT_FAILURE = 'TOPIC_SUBCOUNT_FAILURE'

/**
 * Dispatch GET_TOPIC_ITEM_REQUEST
 */
export const fetchTopic = (type, value, params) => {
  let query = `type=${type}`
  let headers = HEADERS
  let path = 'public'

  if (params && params.token) {
    headers = { ...HEADERS, ...{ Authorization: `Bearer ${params.token}` } }
    path = 'private'
  }

  if (params && params.id) {
    query += `&profile=${params.id}`
  }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/${path}/topic/${value}?${query}`,
      method: GET,
      headers: headers,
      types: [
        GET_TOPIC_ITEM_REQUEST,
        GET_TOPIC_ITEM_SUCCESS,
        GET_TOPIC_ITEM_FAILURE
      ]
    }
  })
}

export const subscribeTopic = (topicId, profileId, params) => {
  const body = {
    topic: topicId,
    profile: profileId,
    action: 'subscribe'
  }
  let headers = HEADERS

  if (params && params.token) {
    headers = { ...HEADERS, ...{ Authorization: `Bearer ${params.token}` } }
  }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/topic`,
      method: POST,
      headers: headers,
      body: JSON.stringify(body),
      types: [
        SUBSCRIBE_TOPIC_REQUEST,
        SUBSCRIBE_TOPIC_SUCCESS,
        SUBSCRIBE_TOPIC_FAILURE
      ]
    }
  })
}

export const unsubscribeTopic = (topicId, profileId, params) => {
  const body = {
    topic: topicId,
    profile: profileId,
    action: 'unsubscribe'
  }
  let headers = HEADERS

  if (params && params.token) {
    headers = { ...HEADERS, ...{ Authorization: `Bearer ${params.token}` } }
  }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/topic`,
      method: POST,
      headers: headers,
      body: JSON.stringify(body),
      types: [
        UNSUBSCRIBE_TOPIC_REQUEST,
        UNSUBSCRIBE_TOPIC_SUCCESS,
        UNSUBSCRIBE_TOPIC_FAILURE
      ]
    }
  })
}

export const getSubscriptionCount = (topicId) => {
  let headers = HEADERS
  let path = 'public'

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/${path}/topic/subscriptions/${topicId}`,
      method: GET,
      headers: headers,
      types: [
        TOPIC_SUBCOUNT_REQUEST,
        TOPIC_SUBCOUNT_SUCCESS,
        TOPIC_SUBCOUNT_FAILURE
      ]
    }
  })
}
