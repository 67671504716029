import {
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions'

const initialState = {
  status: IDLE
}

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case POST_FEEDBACK_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        status: FETCHED
      }
    case POST_FEEDBACK_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default feedback
