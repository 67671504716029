import React from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../utils'
import './title.component.css'

/**
 * Title component
 * @param props
 * @constructor
 */
const Title = (props) => {
  const { className, children, type } = props

  if (!children) {
    return null
  }

  const baseClassName = getClassName('title', [
    { condition: className, trueClassName: className },
    { condition: type, trueClassName: `title--${type}` }
  ])

  switch (type) {
    case 'page':
      return (
        <h1 className={baseClassName}>{children}</h1>
      )
    case 'topicList':
      return (
        <h2 className={baseClassName}>{children}</h2>
      )
    case 'section':
    default:
      return (
        <h3 className={baseClassName}>{children}</h3>
      )
  }
}

Title.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['page', 'section', 'topicList'])
}

Title.defaultProps = {
  type: 'section'
}

export default Title
