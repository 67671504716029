/**
 * Returns link from article
 *
 * @param article
 * @returns {string|boolean}
 */
export const getLnk = (article) => {
  let link = '';

  if (article.article_link && article.article_link.length) {
    link = article.article_link[0].href
  } else if (article.shortcut_url && article.shortcut_url.length) {
    if (article.shortcut_url.length > 1) {
      article.shortcut_url.forEach((url) => {
        if (url.rel === 'alternate') {
          link = url.href
        }
      })
    } else {
      link = article.shortcut_url[0].href
    }
  }

  return link
}
