import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CLEAR_LOGIN_STATUS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SEARCH_SUCCESS,
  GET_PROFILE_SEARCH_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  PASS_RESET_REQUEST,
  PASS_RESET_SUCCESS,
  PASS_RESET_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE,
  SEND_VERIFICATION_EMAIL_REQUEST,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SEND_VERIFICATION_EMAIL_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  CLEAR_PROFILE_ERRORS,
  LOGOUT_SUCCESS,
  FETCHING,
  FETCHED,
  FAILED,
  IDLE,
  CLEAR_EMAIL_VERIFICATION
} from '../../actions'

const initialState = {
  authenticated: false,
  user: {},
  token: {},
  sendVerificationEmail: {
    status: IDLE,
    error: ''
  },
  verifyEmail: {
    status: IDLE,
    error: ''
  },
  data: {
    errorCode: '',
    message: ''
  }
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        status: FETCHING,
        ...initialState
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.profile,
        token: action.payload.token,
        data: {
          errorCode: '',
          message: ''
        },
        status: FETCHED
      }
    case LOGIN_FAILURE:
      const loginPayload = action.payload

      return {
        ...state,
        status: FAILED,
        data: {
          errorCode: loginPayload.status,
          message: loginPayload.response?.message
        },
        authenticated: false
      }
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        data: {
          errorCode: '',
          message: ''
        },
        status: FETCHED
      }
    case GET_PROFILE_SEARCH_SUCCESS:
        return {
          ...state,
          data: {
            result: action.payload,
            errorCode: '',
            message: ''
          },
          status: FETCHED
        }
    case GET_PROFILE_FAILURE:
    case GET_PROFILE_SEARCH_FAILURE:
      return {
        ...state,
        status: FAILED,
        data: {
          errorCode: action.payload.status,
          message: action.payload.response.message
        },
      }
    case CLEAR_LOGIN_STATUS:
      return {
        ...state,
        status: FETCHED,
        data: {
          errorCode: '',
          message: ''
        }
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        user: {},
        token: {},
        authenticated: false
      }
    case REGISTER_REQUEST:
      return {
        ...state,
        status: FETCHING,
        data: {
          errorCode: '',
          message: ''
        }
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.profile,
        token: action.payload.token,
        status: FETCHED
      }
    case REGISTER_FAILURE:
      const registerPayload = action.payload

      return {
        ...state,
        status: FAILED,
        data: {
          errorCode: registerPayload.status,
          message: registerPayload.response.message
        },
        authenticated: false
      }
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        status: FETCHING,
        data: {
          errorCode: '',
          message: ''
        }
      }
    case UPDATE_PROFILE_SUCCESS:
      const profilePayload = action.payload
      return {
        ...state,
        authenticated: true,
        user: profilePayload.profile,
        token: profilePayload.token,
        status: FETCHED
      }
    case UPDATE_PROFILE_FAILURE:
      const profileFailurePayload = action.payload
      return {
        ...state,
        status: FAILED,
        data: {
          errorCode: profileFailurePayload.status,
          message: profileFailurePayload.response.message
        },
      }
    case PASS_RESET_REQUEST:
      return {
        ...state,
        status: FETCHING,
        data: {
          errorCode: '',
          message: ''
        }
      }
    case PASS_RESET_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        data: {
          errorCode: '',
          message: ''
        }
      }
    case PASS_RESET_FAILURE:
      return {
        ...state,
        status: FAILED,
        data: {
          pass: {
            errorCode: action.payload.status,
            message: action.payload.response.message
          }
        }
      }
    case CANCEL_SUBSCRIPTION_REQUEST:
        return {
          ...state,
          status: FETCHING,
          data: {
            errorCode: '',
            message: ''
          }
        }
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        data: {
          errorCode: '',
          message: ''
        }
      }
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        status: FAILED,
        data: {
          pass: {
            errorCode: action.payload.status,
            message: action.payload.response.message
          }
        }
      }
    case REACTIVATE_SUBSCRIPTION_REQUEST:
        return {
          ...state,
          status: FETCHING,
          data: {
            errorCode: '',
            message: ''
          }
        }
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        data: {
          errorCode: '',
          message: ''
        }
      }
    case REACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        status: FAILED,
        data: {
          pass: {
            errorCode: action.payload.status,
            message: action.payload.response.message
          }
        }
      }
    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmail: {
          status: FETCHING,
          error: ''
        }
      }
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmail: {
          status: FETCHED,
          error: ''
        }
      }
    case SEND_VERIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        sendVerificationEmail: {
          status: FAILED,
          error: action.payload.response.message
        }
      }
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifyEmail: {
          status: FETCHING
        }
      }
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isEmailVerified: true
        },
        verifyEmail: {
          status: FETCHED
        }
      }
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyEmail: {
          status: FAILED,
          error: action.payload.response.message
        }
      }
    case CLEAR_EMAIL_VERIFICATION:
      return {
        ...state,
        verifyEmail: {
          status: IDLE,
          error: ''
        },
        sendVerificationEmail: {
          status: IDLE,
          error: ''
        }
      }
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        status: FETCHED,
        data: {
          errorCode: '',
          message: '',
          pass: {
            errorCode: '',
            message: ''
          }
        }
      }
    default:
      return state
  }
}

export default profile
