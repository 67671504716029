import React from 'react'
import Button from '../../button/button.component'
import config from '../../../config'
import './OAuthLoginButton.component.css'

const supportedProviders = new Set(["discord", "apple", "google"]);

const providerImages = {
    "discord": "https://storage.googleapis.com/tattle-public-images/site/oauth-icons/discord.png",
    "apple": "https://storage.googleapis.com/tattle-public-images/site/oauth-icons/apple.png",
    "google": "https://storage.googleapis.com/tattle-public-images/site/oauth-icons/google.png"
}

const OAuthLoginButton = ({ oAuthProvider="" }) => {
    if(oAuthProvider === "" || !supportedProviders.has(oAuthProvider)){
        throw Error(`Invalid social platform selected, please select from: ${Array.from(supportedProviders)}`)
    }

    const handleButtonClick = () => {
        switch(oAuthProvider) {
            case "discord":
                window.open(config.DISCORD_OAUTH2_URL, '_self')
                break
            case "apple":
                window.open("", '_self')
                break
            case "google": 
                window.open("", '_self')
                break
            default:
                break
        }
    }

    const label = oAuthProvider.charAt(0).toUpperCase() + oAuthProvider.slice(1);



    return (
        <Button
            className="oauth-login-button"
            label={`Continue with ${label}`}
            image={providerImages[oAuthProvider]}
            onClick={handleButtonClick}
        />
    )
}
export default OAuthLoginButton