import React, {PureComponent} from 'react'
import { compose } from 'redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import { withStyles } from '@mui/styles'
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from '../../../../components';
const Transition = React.forwardRef(function Transition (props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const styles = {
    dialog: {
        backgroundColor: '#373749',
        borderColor: '#373749',
        width: '450px',
        height: '310px',
        position: 'relative',
        borderRadius: '15px'
    },
    header: {
        backgroundColor: '#373749',
        height: '50px',
        textAlign: '-webkit-center'
    },
    title: {
        color: '#eeeeee',
        top: '20px',
        height: '36px',
        fontSize: '20px',
        position: 'relative'
    },
    body: {
        height: '190px',
        margin: '10px 60px',
        position: 'relative'
    },
    row : {
        marginBottom: '10px'
    },
    label: {
        display: 'block',
        marginBottom: '3px',
        color: '#b3b3b3'
    },
    input: {
        width: '100%',
        background: '#141415',
        color: '#ffffff',
        height: '45px',
        lineHeight: '40px',
        borderRadius: '5px',
        border: '2px solid #141415',
        padding: '0 10px'
    },
    button: {
        width: '100px',
        height: '40px',
        borderRadius: '8px',
        background: '#0066ff',
        color: '#ffffff',
        border: '1px solid #373749',
        cursor: 'pointer',
        position: 'absolute',
        bottom: '-15px',
        right: '0'
    },
    footer: {
        height: '50px',
        textAlign: 'center'
    },
    paper: {
        margin: 0,
        maxWidth: '800px',
        borderRadius: '20px',
        border: '0',
        backgroundColor: '#373749'
    },
    error: {
        color: '#ff0066',
        marginTop: '5px'
    }
}

class AddServerModal extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false
        }

        this.handleFormClose = this.handleFormClose.bind(this)
        this.onAddServer = this.onAddServer.bind(this)
    }

    handleFormClose() {
        const { onClose } = this.props
        onClose && onClose()
    }

    onAddServer(fields) {
        const { onUpdate } = this.props
        onUpdate && onUpdate('add', fields)
        this.handleFormClose()
    }

    render() {
        const { isVisible, classes } = this.props

        const fields = {
            server_name: '',
            server_id: ''
        }

        return (
            <Dialog
                open={isVisible}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={this.handleFormClose}
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
                classes={{ paper: classes.paper }}
            >
                <DialogContent
                    className={classes.dialog}
                    classes={{ root: 'modal-container' }}
                >
                    <div className={classes.header}>
                        <h3 className={classes.title}>Add Server</h3>
                        <IconButton
                            aria-label='Close'
                            classes={{ root: 'modal-actions' }}
                            onClick={this.handleFormClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>

                    </div>
                    <div className={classes.body}>
                        <Formik
                            initialValues={fields}
                            validate={values => {
                                const errors = {};
                                if (!values.server_id) {
                                    errors.server_id = 'Required';
                                } else if (!values.server_name) {
                                    errors.server_name = 'Required';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.onAddServer(values)
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form className='add-server-form'>
                                    <div className={classes.row}>
                                        <label htmlFor='server_name' className={classes.label}>Server Name:</label>
                                        <Field type="text" name="server_name" className={classes.input} />
                                        <ErrorMessage name="server_name" component="div" className={classes.error} />
                                    </div>
                                    <div className={classes.row}>
                                        <label htmlFor='server_id'  className={classes.label}>Server ID:</label>
                                        <Field type="text" name="server_id" className={classes.input} />
                                        <ErrorMessage name="server_id" component="div" className={classes.error} />
                                    </div>
                                    <div className={classes.row}>
                                        <Button
                                            className={classes.button}
                                            label='Add'
                                            type='submit'
                                            />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className={classes.footer}>

                    </div>

                </DialogContent>
            </Dialog>
        );
    }


}

export default compose(
    withStyles(styles),
)(AddServerModal);
