import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../config'
import { GET } from '../index'

/**
 * Topic Action Type Constants
 * @type {string}
 */
export const GET_POLICY_REQUEST = 'GET_POLICY_REQUEST'
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS'
export const GET_POLICY_FAILURE = 'GET_POLICY_FAILURE'

/**
 * Dispatch GET_TOPIC_LIST_REQUEST
 */
export const fetchPolicyByType = (type) => ({
  [RSAA]: {
    endpoint: `${API_URL}/public/policy/${type}`,
    method: GET,
    headers: HEADERS,
    types: [
      GET_POLICY_REQUEST,
      GET_POLICY_SUCCESS,
      GET_POLICY_FAILURE
    ]
  }
})
