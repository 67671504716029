import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom'
import ReactGA from 'react-ga4'
import config from '../../config'

import AuthService from '../../services/auth.service'

import NavigationContainer from '../navigation/navigation.container'
import SearchContainer from '../search/search.container'
import TrendingContainer from '../trending/trending.container'
import ExploreContainer from '../explore/explore.container'
import CategoryContainer from '../category/category.container'
import TimelineContainer from '../timeline/timeline.container'
import PolicyPage from '../../pages/policies/policies.page'
import HomePage from '../../pages/home/home.page'
import ArticleContainer from '../article/article.container'
import BookmarkContainer from '../bookmark/bookmark.container'
import BotContainer from '../bot/bot.container'
import ErrorPage from '../../pages/error/error.page'
import WelcomePage from '../../pages/welcome/welcome.page'
import SettingPage from '../../pages/setting/setting.page'
import AccountSettingPage from '../../pages/setting/account/account.setting.page'
import MembershipSettingPage from '../../pages/setting/membership/membership.setting.page'
import HelpLegalSettingPage from '../../pages/setting/help-legal/help-legal.setting.page'
import UpdateUsername from '../../pages/setting/account/updateAccount/updateUsername.account'
import UpdatePhone from '../../pages/setting/account/updateAccount/updatePhone.account'
import UpdatePassword from '../../pages/setting/account/updateAccount/updatePassword.account'
import VerifyEmail from '../../pages/verifyEmail/verifyEmail.page'
import ForgotPassword from '../../pages/forgotPassword/forgotPassword.page'
import DiscordOauth2Page from '../../pages/oauth2/discord/discord.page'

import { createTheme, ThemeProvider } from '@mui/material/styles';

import './root.container.css'
import '../../resources/scss/global.css'

const DEFAULT_THEME = createTheme();

const PrivateRoute = ({children, redirectTo, auth}) => {
  return auth.isLoggedIn() ? children : <Navigate replace to={redirectTo}/>
}

class RootContainer extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      googleAnalyticsInitialized: false,
      auth: new AuthService({})
    }

    this.initGoogleAnalytics = this.initGoogleAnalytics.bind(this)
  }

  componentDidMount () {
    const { history } = this.props
    this.initGoogleAnalytics(history)
  }

  /**
   * Initialize google analytics to track page changes
   *
   * @param history
   * @returns {boolean}
   */
  initGoogleAnalytics (history) {
    ReactGA.initialize(config.GOOGLE_API_KEY)
    history.listen((location) => ReactGA.send({ hitType: 'pageview', page: location.pathname}))
  }

  /**
   * Render RootContainer
   */
  render () {
    const { history } = this.props
    const { auth } = this.state
	  const authenticated = auth.isLoggedIn()

    return (
      <div className='root-container'>
        <Router>
          <ThemeProvider theme={DEFAULT_THEME}>
            <div className='root-container_inner'>
              <NavigationContainer
                history={history}
                auth={auth}
              />
              <div className='body'>
                <div id="top-of-page"></div>
                <Routes>
                  <Route path='/' element={<Navigate replace to='/trending'/>}/>
                  <Route path='/dashboard' element={<Navigate replace to='/trending'/>}/>
                  <Route path='/trending' element={<TrendingContainer history={history} auth={auth} />}/>
                  <Route path='/explore' element={<CategoryContainer history={history} />}/>
                  <Route path='/explore/:id' element={<ExploreContainer history={history} />}/>

                  <Route path='/topic' element={<Navigate replace to='/explore'/>}/>
                  <Route path='/topic/:id' element={<TimelineContainer history={history} auth={auth} />}/>
                  <Route path='/topic/:id/:id' element={<ArticleContainer history={history} auth={auth} />}/>

                  <Route path='/bookmarks' element={<BookmarkContainer history={history} auth={auth} />}/>
                  <Route path='/bot' element={<BotContainer history={history} auth={auth} />}/>
                  <Route path='/termsofservice' element={<PolicyPage page={'termsofservice'} />}/>
                  <Route path='/privacypolicy' element={<PolicyPage page={'privacypolicy'} />}/>
                  <Route path='/verifyEmail' element={<VerifyEmail />}/>
                  <Route path='/forgotPassword' element={<ForgotPassword />}/>

                  {/* OAuth2 Paths */}
                  <Route path='/oauth2/discord' element={<DiscordOauth2Page />}/>

                  {authenticated ?
                    <Route path='/home' element={
                      <PrivateRoute auth={auth} redirectTo={'/trending'}>
                        <HomePage history={history} auth={auth} />
                      </PrivateRoute>
                    }/>
                  :
                    <Route path='/home' element={<WelcomePage history={history} auth={auth} />}/>
                  }

                  <Route path='/settings' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <SettingPage history={history} auth={auth} />
                    </PrivateRoute>
                  }/>
                  <Route path='/settings/account' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <AccountSettingPage history={history} auth={auth} />
                    </PrivateRoute>
                  }/>
                  <Route path='/settings/updateUsername' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <UpdateUsername  history={history} auth={auth} />
                    </PrivateRoute>
                  }/>
                  <Route path='/settings/updatePhone' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <UpdatePhone  history={history} auth={auth} />
                    </PrivateRoute>
                  }/>
                  <Route path='/settings/updatePassword' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <UpdatePassword  history={history} auth={auth} />
                    </PrivateRoute>
                  }/>
                  <Route path='/settings/membership' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <MembershipSettingPage  history={history} auth={auth} />
                    </PrivateRoute>
                  }/>
                  <Route path='/settings/help' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <HelpLegalSettingPage history={history} auth={auth} />
                    </PrivateRoute>
                  }/>
                  <Route path='/settings/:id' element={
                    <PrivateRoute auth={auth} redirectTo={'/trending'}>
                      <SettingPage history={history} auth={auth} />
                    </PrivateRoute>
                  }/>

                  <Route path='*' element={<ErrorPage/>}/>
                </Routes>
              </div>
              <SearchContainer history={history} />
            </div>
          </ThemeProvider>
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { profile } = state

  return {
    authenticated: profile.authenticated
  }
}

export default connect(mapStateToProps)(RootContainer)
