import React, { PureComponent } from 'react'
import { ViewSwitch, Modal, LoginComponent, RegisterForm, ForgotPasswordForm } from '../../components'

class AuthenticationComponent extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      activeViewId: 'login',
      isVisible: false,

      registrationForm: {
        password: '',
        confirmPassword: '',
        email: '',
        name: ''
      },

      loginForm: {
        username: '',
        password: ''
      }
    }

    this.handleViewSwitch = this.handleViewSwitch.bind(this)
    this.resetForm = this.resetForm.bind(this)

    this.renderLoginForm = this.renderLoginForm.bind(this)
    this.renderRegistrationForm = this.renderRegistrationForm.bind(this)
    this.renderForgotPasswordForm = this.renderForgotPasswordForm.bind(this)
    this.handleLoginFieldChange = this.handleLoginFieldChange.bind(this)
    this.handleRegistrationFieldChange = this.handleRegistrationFieldChange.bind(this)
    this.handleModalHide = this.handleModalHide.bind(this)
    this.handleFormClick = this.handleFormClick.bind(this)
  }

  componentDidMount () {
    const { formType } = this.props

    this.setState({
      activeViewId: formType || 'login'
    })
  }

  resetForm (formType) {
    switch (formType) {
      case 'registration':
        return this.setState({
          registrationForm: {
            email: '',
            password: '',
            confirmPassword: '',
            name: ''
          }
        })
      case 'login':
      default:
        return this.setState({
          loginForm: {
            username: '',
            password: ''
          }
        })
    }
  }

  handleViewSwitch (newActiveViewId) {
    const { activeViewId } = this.state
    const { onClearErrorStatus } = this.props

    onClearErrorStatus()

    if (activeViewId !== newActiveViewId) {
      this.setState({
        activeViewId: newActiveViewId
      }, () => this.resetForm(activeViewId))
    }
  }

  handleLoginFieldChange (event, value) {
    const { loginForm } = this.state
    const { name } = (event && event.target) || {}

    if (!name) {
      return
    }

    this.setState({
      loginForm: {
        ...loginForm,
        [name]: value.trim()
      }
    })
  }

  handleRegistrationFieldChange (event, value) {
    const { registrationForm } = this.state
    const { name } = (event && event.target) || {}

    if (!name) {
      return
    }

    if (['email', 'password', 'confirmPassword'].indexOf(name) > -1) {
      value = value.trim()
    }

    this.setState({
      registrationForm: {
        ...registrationForm,
        [name]: value
      }
    })
  }

  handleFormClick (type, params) {
    const { onLoginClick, onRegisterClick, onForgotPasswordClick } = this.props

		if (!params) {
			return false
		}

		if (type === 'login' && params.username && params.password) {
			onLoginClick && onLoginClick(params)
		}

		if (type === 'registration' && params.email && params.password && params.confirmPassword) {
      onRegisterClick && onRegisterClick(params)
    }

    if (type === 'forgotPassword' && params.email) {
      onForgotPasswordClick && onForgotPasswordClick(params)
    }

  }

  /**
   * Login form
   * @returns {XML}
   */
  renderLoginForm () {
    const { username, password } = this.state.loginForm
    const { profile } = this.props

    const errorCode = (profile.data && profile.data.errorCode) || ''
    const message = (profile.data && profile.data.message) || ''

    return (
      <LoginComponent
        username={username}
        password={password}
        authStatus={errorCode}
        authMessage={message}
        onLoginClick={(params) => this.handleFormClick('login', { ...params })}
        handleLoginFieldChange={(event, value) => this.handleLoginFieldChange(event, value)}
        handleViewSwitch={(viewId) => this.handleViewSwitch(viewId)}
      />
    )
  }

  /**
   * Registration Form
   * @returns {XML}
   */
  renderRegistrationForm () {
    const { password, confirmPassword, email, name, phone } = this.state.registrationForm
    const { profile } = this.props

    const errorCode = (profile.data && profile.data.errorCode) || ''
    const message = (profile.data && profile.data.message) || ''

    return (
      <RegisterForm
        password={password}
        confirmPassword={confirmPassword}
        email={email}
        name={name}
        phone={phone}
        authStatus={errorCode}
        authMessage={message}
        onRegisterClick={(params) => this.handleFormClick('registration', { ...params })}
        handleRegistrationFieldChange={(event, value) => this.handleRegistrationFieldChange(event, value)}
        handleViewSwitch={(viewId) => this.handleViewSwitch(viewId)}
      />
    )
  }

  renderForgotPasswordForm () {
    return (
      <ForgotPasswordForm
        handleViewSwitch={(viewId) => this.handleViewSwitch(viewId)}
        onForgotPasswordClick={(params) => this.handleFormClick('forgotPassword', { ...params })}
      />
    )
  }

  handleModalHide () {
    const { onHide, onClearErrorStatus } = this.props

    this.setState({
      activeViewId: 'login'
    })

    onClearErrorStatus()
    onHide()
  }

  render () {
    const { activeViewId } = this.state
    const { isVisible } = this.props

    return (
      <Modal
        isVisible={isVisible}
        disableEscToClose={false}
        includeExit
        onHide={this.handleModalHide}
      >
        <ViewSwitch
          activeViewId={activeViewId}
          views={[{
            id: 'login',
            component: this.renderLoginForm
          }, {
            id: 'registration',
            component: this.renderRegistrationForm
          },
          {
            id: 'forgotPassword',
            component: this.renderForgotPasswordForm
          }]}
          handleViewSwitch={(viewId) => this.handleViewSwitch(viewId)}
        />
      </Modal>
    )
  }
}

export default AuthenticationComponent
