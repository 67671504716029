import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { fetchCategoriesWithTopics, FETCHING } from '../../actions'
import { LoadingMask, CategoryTopicList } from '../../components'
import './category.container.css'

class CategoryContainer extends PureComponent {
	constructor (props) {
		super(props)

		this.state = {}

		this.fetchTopics = this.fetchTopics.bind(this)
	}

	componentDidMount (prevProps) {
		this.fetchTopics()
	}

	fetchTopics () {
		const { fetchTopics } = this.props
		fetchTopics()
	}

	render () {
		const { categories, categoryStatus } = this.props
		const noData = !categories || !categories.length

		return (
			<div className='explore-container'>
				{categoryStatus === FETCHING ? (
					<LoadingMask
					/>
				) : (
					<section className='categories'>
						{noData ?
							<div className='categories--no-data'>No Categories Available</div>
							:
							<div className='categories-list'>
								{categories.map((category, idx) => {
									return <div className='category-row' key={`item-${idx}`}>
										<CategoryTopicList
											topics={category.topics}
											categoryName={category.label}
											category={category.name}
										/>
									</div>
								})}
							</div>
						}
					</section>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { profile, categories } = state
	const { authenticated, authenticationDate } = profile

	return {
		authenticated,
		authenticationDate,
		categories: categories.data || [],
		categoryStatus: categories.status
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchTopics: () => dispatch(fetchCategoriesWithTopics())
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer)
