import {RSAA} from 'redux-api-middleware'
import {API_URL, HEADERS} from '../../config'
import {POST, PUT, GET} from '../index'
import queryString from 'query-string'

/**
 * Article Action Type Constants
 * @type {string}
 */
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const CLEAR_LOGIN_STATUS = 'CLEAR_LOGIN_STATUS'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'

export const GET_PROFILE_SEARCH_REQUEST = 'GET_PROFILE_SEARCH_REQUEST'
export const GET_PROFILE_SEARCH_SUCCESS = 'GET_PROFILE_SEARCH_SUCCESS'
export const GET_PROFILE_SEARCH_FAILURE = 'GET_PROFILE_SEARCH_FAILURE'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const PASS_RESET_REQUEST = 'PASS_RESET_REQUEST'
export const PASS_RESET_SUCCESS = 'PASS_RESET_SUCCESS'
export const PASS_RESET_FAILURE = 'PASS_RESET_FAILURE'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE'

export const REACTIVATE_SUBSCRIPTION_REQUEST = 'REACTIVATE_SUBSCRIPTION_REQUEST'
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'REACTIVATE_SUBSCRIPTION_SUCCESS'
export const REACTIVATE_SUBSCRIPTION_FAILURE = 'REACTIVATE_SUBSCRIPTION_FAILURE'

export const SEND_VERIFICATION_EMAIL_REQUEST = 'SEND_VERIFICATION_EMAIL_REQUEST'
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS'
export const SEND_VERIFICATION_EMAIL_FAILURE = 'SEND_VERIFICATION_EMAIL_FAILURE'

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE'

export const SEND_FORGOT_PASSWORD_EMAIL_REQUEST = 'SEND_FORGOT_PASSWORD_EMAIL_REQUEST'
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS'
export const SEND_FORGOT_PASSWORD_EMAIL_FAILURE = 'SEND_FORGOT_PASSWORD_EMAIL_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const CLEAR_EMAIL_VERIFICATION = 'CLEAR_EMAIL_VERIFICATION'

export const CLEAR_FORGOT_PASSWORD_EMAIL = 'CLEAR_FORGOT_PASSWORD_EMAIL'

export const CLEAR_PROFILE_ERRORS = 'CLEAR_PROFILE_ERRORS'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'


export const getProfile = (id, auth) => {
    let headers = {...HEADERS, ...{Authorization: `Bearer ${auth.token}`}}

    return ({
        [RSAA]: {
            endpoint: `${API_URL}/private/profile/${id}`,
            method: GET,
            headers: headers,
            types: [
                GET_PROFILE_REQUEST,
                GET_PROFILE_SUCCESS,
                GET_PROFILE_FAILURE
            ]
        }
    })
}


export const getProfileSearch = (id, params, auth) => {
    let headers = {...HEADERS, ...{Authorization: `Bearer ${auth.token}`}}
    const query = queryString.stringify(params)
    return ({
        [RSAA]: {
            endpoint: `${API_URL}/private/profile/search/${id}?${query}`,
            method: GET,
            headers: headers,
            types: [
                GET_PROFILE_SEARCH_REQUEST,
                GET_PROFILE_SEARCH_SUCCESS,
                GET_PROFILE_SEARCH_FAILURE
            ]
        }
    })
}

/**
 * Dispatch LOGIN
 */
export const loginProfile = (body) => ({
    [RSAA]: {
        endpoint: `${API_URL}/auth/login`,
        method: POST,
        headers: HEADERS,
        body: JSON.stringify(body),
        types: [
            LOGIN_REQUEST,
            LOGIN_SUCCESS,
            LOGIN_FAILURE
        ]
    }
})

export const clearLoginStatus = () => ({
    type: CLEAR_LOGIN_STATUS
})

export const registerProfile = (body) => ({
    [RSAA]: {
        endpoint: `${API_URL}/auth/register`,
        method: POST,
        headers: HEADERS,
        body: JSON.stringify(body),
        types: [
            REGISTER_REQUEST,
            REGISTER_SUCCESS,
            REGISTER_FAILURE
        ]
    }
})

export const authenticateWithDiscord = (code) => ({
    [RSAA]: {
        endpoint: `${API_URL}/auth/discord?code=${code}`,
        method: POST,
        headers: HEADERS,
        types: [
            LOGIN_REQUEST,
            LOGIN_SUCCESS,
            LOGIN_FAILURE
        ]
    }
})

export const updateProfile = (id, body, auth) => {
    let headers = {...HEADERS, ...{Authorization: `Bearer ${auth.token}`}}
    return ({
        [RSAA]: {
            endpoint: `${API_URL}/private/profile/${id}`,
            method: PUT,
            headers: headers,
            body: JSON.stringify(body),
            types: [
                UPDATE_PROFILE_REQUEST,
                UPDATE_PROFILE_SUCCESS,
                UPDATE_PROFILE_FAILURE
            ]
        }
    })
}

export const cancelSubscription = (body, auth) => {
    let headers = {...HEADERS, ...{Authorization: `Bearer ${auth.token}`}}
    return ({
        [RSAA]: {
            endpoint: `${API_URL}/private/profile/entitlement/cancel`,
            method: PUT,
            headers: headers,
            body: JSON.stringify(body),
            types: [
                CANCEL_SUBSCRIPTION_REQUEST,
                CANCEL_SUBSCRIPTION_SUCCESS,
                CANCEL_SUBSCRIPTION_FAILURE
            ]
        }
    })
}

export const reactivateSubscription = (body, auth) => {
    let headers = {...HEADERS, ...{Authorization: `Bearer ${auth.token}`}}
    return ({
        [RSAA]: {
            endpoint: `${API_URL}/private/profile/entitlement/reactivate`,
            method: PUT,
            headers: headers,
            body: JSON.stringify(body),
            types: [
                REACTIVATE_SUBSCRIPTION_REQUEST,
                REACTIVATE_SUBSCRIPTION_SUCCESS,
                REACTIVATE_SUBSCRIPTION_FAILURE
            ]
        }
    })
}

export const resetPassword = (id, body, auth) => {
    let headers = {...HEADERS, ...{Authorization: `Bearer ${auth.token}`}}

    return ({
        [RSAA]: {
            endpoint: `${API_URL}/private/profile/reset/${id}`,
            method: PUT,
            headers: headers,
            body: JSON.stringify(body),
            types: [
                PASS_RESET_REQUEST,
                PASS_RESET_SUCCESS,
                PASS_RESET_FAILURE
            ]
        }
    })
}

export const clearErrors = () => ({
    type: CLEAR_PROFILE_ERRORS
})

/**
 * Dispatch SET_APP_THEME
 */
export const logoutProfile = () => ({
    type: LOGOUT_SUCCESS
})

export const sendVerificationEmail = (email, displayName, auth) => {
    const headers = {...HEADERS, ...{Authorization: `Bearer ${auth.token}`}}
    return {
        [RSAA]: {
            endpoint: `${API_URL}/auth/sendVerificationEmail`,
            method: POST,
            headers,
            body: JSON.stringify({email, displayName}),
            types: [
                SEND_VERIFICATION_EMAIL_REQUEST,
                SEND_VERIFICATION_EMAIL_SUCCESS,
                SEND_VERIFICATION_EMAIL_FAILURE
            ]
        }
    }
}

export const verifyEmail = (email, verificationToken) => {
    return {
        [RSAA]: {
            endpoint: `${API_URL}/auth/verifyEmail`,
            method: POST,
            headers: HEADERS,
            body: JSON.stringify({email, verificationToken}),
            types: [
                VERIFY_EMAIL_REQUEST,
                VERIFY_EMAIL_SUCCESS,
                VERIFY_EMAIL_FAILURE
            ]
        }
    }
}

export const sendForgotPasswordEmail = (email) => {
    return {
        [RSAA]: {
            endpoint: `${API_URL}/auth/sendForgotPasswordEmail`,
            method: POST,
            headers: HEADERS,
            body: JSON.stringify({email}),
            types: [
                SEND_FORGOT_PASSWORD_EMAIL_REQUEST,
                SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
                SEND_FORGOT_PASSWORD_EMAIL_FAILURE
            ]
        }
    }
}

export const updatePassword = (email, newPassword, forgotPasswordToken) => {
    return {
        [RSAA]: {
            endpoint: `${API_URL}/auth/updatePassword`,
            method: PUT,
            headers: HEADERS,
            body: JSON.stringify({email, newPassword, forgotPasswordToken}),
            types: [
                UPDATE_PASSWORD_REQUEST,
                UPDATE_PASSWORD_SUCCESS,
                UPDATE_PASSWORD_FAILURE
            ]
        }
    }
}

export const clearForgotPasswordEmail = () => ({
    type: CLEAR_FORGOT_PASSWORD_EMAIL
})  

export const clearEmailVerification = () => ({
    type: CLEAR_EMAIL_VERIFICATION
})