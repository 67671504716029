import {
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  FAILED,
  FETCHING,
  FETCHED
} from '../../actions'

const initialState = {
  data: []
}


const subscription = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_SUBSCRIPTIONS_SUCCESS:
      const payload = action.payload

      return {
        ...state,
        status: FETCHED,
        data: payload.data
      }
    case GET_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        status: FAILED,
        data: []
      }
    default:
        return state
  }

}

export default subscription