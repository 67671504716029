import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { fetchTopicsByCategoryId, FETCHING } from '../../actions'
import { LoadingMask } from '../../components'
import { TopicList } from '../../components/topic'
import './explore.container.css'
import { last } from 'lodash'

class ExploreContainer extends PureComponent {
	constructor (props) {
		super(props)

		this.state = {}

		this.fetchTopics = this.fetchTopics.bind(this)
	}

	componentDidMount (prevProps) {
		this.fetchTopics()
	}

	fetchTopics () {
		const { fetchTopicsByCategoryId } = this.props
		const categoryId = last(window.location.pathname.split('/'))

		fetchTopicsByCategoryId(categoryId)
	}

	render () {
		const { topics, topicStatus } = this.props

		return (
			<div className='explore-container'>
				{topicStatus === FETCHING ? (
					<LoadingMask
					/>
				) : (
					<TopicList
						topics={topics}
					/>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { search, profile, topic } = state
	const { authenticated, authenticationDate } = profile

	return {
		authenticated,
		authenticationDate,
		topics: topic.list.data || [],
		topicStatus: topic.list.status,
		searchQuery: search.query
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchTopicsByCategoryId: (id) => dispatch(fetchTopicsByCategoryId(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExploreContainer)