import {PureComponent} from 'react'
import {connect} from 'react-redux'
import {FETCHING, FETCHED, FAILED} from "../../../actions";
import { sendFeedback } from '../../../actions';
import {Button, LoadingMask, Title, TattleLink, Modal } from "../../../components";
import Snackbar from '@mui/material/Snackbar';
import "./help-legal.setting.page.css"

class HelpLegalSettingPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,
            toastVisible: false
        }
    }

    options = [
        {
            "name": "Terms of Service",
            "link": "/termsofservice"
        },
        {
            "name": "Privacy Policy",
            "link": "/privacypolicy"
        },
        {
            "name": "Discord Support Server",
            "link": "https://discord.gg/pvQ4Bdq"
        }
    ]

    toggleSuggestionModal = () => {
        this.setState({
            ...this.state,
            modalVisible: !this.state.modalVisible
        });
    }

    handleSubmitFeedback = () => {
        if(!this.state.feedback){
            return;
        }

        this.props.sendFeedback(this.props.auth, this.state.feedback, new Date().getTime(), this.props.user.username, navigator.userAgent);
        
        this.toggleSuggestionModal();
        this.setState({
            feedback: "",
            toastVisible: true
        })
    }

    render() {
        const { fetchingStatus } = this.props

        let toastLabel = '';
        switch(this.props.feedbackStatus){
            case FETCHING:
                toastLabel = "Submitting feedback..."
                break;
            case FETCHED:
                toastLabel = "Feedback submitted. Thank you."
                break;
            case FAILED:
                toastLabel = "Failed to submit feedback, please try again later"
                break; 
            default:
                toastLabel = ""
        }

        return (
            <div className='membership-setting-page'>
                {fetchingStatus === FETCHING ? (
                    <LoadingMask
                    />
                ) : (
                    
                    <Title className={'centered'}>
                        <TattleLink to={'/settings'}>
                            <Button
                                className="back-button"
                                theme="transparent"
                                icon="arrow_back"
                                label='Help & Legal'
                            />
                        </TattleLink>
                    </Title>
                )}
                <div className='setting-page'>
                    <ul className='settings-list'>
                        {this.options.map((option) => {
                            const name = option["name"];
                            const link = option["link"];

                            return (
                                <TattleLink
                                    to={link}
                                    key={name}
                                    className='settings-link'
                                >
                                    <li>
                                        <div>{name}</div>
                                        <div className='more-arrow'>
                                            <i className='icon icon-arrow_forward'/>
                                        </div>
                                    </li>
                                </TattleLink>
                            )
                        })}
                        <li onClick={this.toggleSuggestionModal}>
                            <div>Suggestions & Feedback</div>
                            <div className='more-arrow'>
                                <i className='icon icon-arrow_forward'/>
                            </div>
                        </li>
                    </ul>
                </div>
                <Modal className="suggestion-modal" isVisible={this.state.modalVisible} onHide={this.toggleSuggestionModal} includeExit title={"Share you feedback with us"} >
                    <div className="suggestion-modal_inner">
                        <textarea className="suggestion-modal_textarea" onChange={e => this.setState({...this.state, feedback: e.target.value})} value={this.state.feedback} placeholder='Tell us how we can improve...'></textarea>
                        <Button className="suggestion-modal_submit-button" onClick={this.handleSubmitFeedback} label={"Submit Feedback"} />
                    </div>
                    {this.props.feedbackStatus === FETCHING?
                        <LoadingMask className="modal-loading-mask" fixed={true}
                        />
                        : null
                    }
                </Modal>
                <Snackbar 
                    anchorOrigin ={{vertical: "top", horizontal: "center"}} 
                    open={this.state.toastVisible}  
                    autoHideDuration= {10000} 
                    onClose={
                        () => this.setState({toastVisible: false})
                    } 
                    message={toastLabel} 
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    const { profile, feedback } = state

    return {
        fetchingStatus: profile.status,
        user: profile.user,
        feedbackStatus: feedback.status
    }
}

const mapDispatchToProps = (dispatch) => ({
    sendFeedback: (auth, feedback, timestamp, userEmail, userAgent) => dispatch(sendFeedback(auth, feedback, timestamp, userEmail, userAgent))
})

export default connect(mapStateToProps, mapDispatchToProps)(HelpLegalSettingPage)