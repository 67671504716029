import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { thunk } from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { createBrowserHistory } from 'history'

import rootReducer from '../reducers'

const persistConfig = {
  key: 'root',
  whitelist: ['ui', 'profile'],
  version: 0.91,
  storage
}

const createStoreWithMiddleware = applyMiddleware(apiMiddleware, thunk)(createStore)
const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
)

const store = () => {
	const params = [persistedReducer]

	if (process.env.REACT_APP_ENV !== 'production') {
		params.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	const store = createStoreWithMiddleware(...params)
	const persistor = persistStore(store)
	const history = createBrowserHistory()

	return {
		store,
		persistor,
		history
	}
}

export default store
