import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {updatePassword} from "../../actions";
import { Title } from "../../components";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import './forgotPassword.page.css'
import { Snackbar } from '@mui/material';

const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
                .required("Please enter a password")
                .min(8, "Password must be at least 8 characters")
                .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/, "Password must contain at least one uppercase, one lowercase, one number, and one special character"),
    confirmPassword: Yup.string()
                .required("Please confirm your password")
                .oneOf([Yup.ref('password')], 'Passwords must match')
})

const ForgotPassword = ({updatePassword}) => {
    const [email, setEmail] = React.useState('');
    const [forgotPasswordToken, setForgotPasswordToken] = React.useState('');
    const [toastOpen, setToastOpen] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');

    useEffect(() => {
        try{
            // Get token and email from URL
            const params = new URL(window.location.toString()).searchParams
            const email = params.get("email")
            const forgotPasswordToken = params.get("forgotPasswordToken")
    
            if(!email || !forgotPasswordToken) {
                window.location = '/trending'
                return
            }
    
            setEmail(email)
            setForgotPasswordToken(forgotPasswordToken)
        }catch(e){
            console.error(e)
            // Send user to trending page
            window.location = '/trending'
        }
    }, [])

    return (
        <div className='forgot-password-page'>
            <Title className={'centered'}>
                <h4>Change Password</h4>
            </Title>
            <div className='forgot-password-page_inner'>
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: ''
                    }}
                    // enableReinitialize
                    validationSchema={ForgotPasswordSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {           
                        setToastMessage('Updating password...')
                        setToastOpen(true)
                        const { payload } = await updatePassword(email, values.password, forgotPasswordToken)
                        if(payload?.success === true) {
                            setToastMessage('Password updated successfully! Redirecting to login page...')
                            setToastOpen(true)
                            // wait 2 seconds then redirect
                            setTimeout(() => {
                                window.location = "/trending?login=true"
                            }, 2000)
                        }else {
                            setToastMessage(payload.response.message)
                            setToastOpen(true)
                        }
                        setSubmitting(false)
                    }}
                >  
                {({ errors, touched, isSubmitting }) => {
                    return (
                        <Form className='update-form'>
                            <div className='row'>
                                You are updating the password for {email}
                            </div>
                                <div className='row'>
                                    <label htmlFor='newPassword'>New</label>
                                    <Field type="password" name="password" placeholder="enter new password" />
                                </div>
                                <div className='row'>        
                                    <label htmlFor='confirmPassword'>Confirm</label>
                                    <Field type="password" name="confirmPassword" placeholder="confirm new password" />
                                </div>
                                <div className='row'>
                                    {(errors.password && touched.password) || (errors.confirmPassword && touched.confirmPassword) ? (<div className="field-error">{errors.password || errors.confirmPassword}</div>) : null}
                                </div>
                                <div className='row'>
                                    <button type="submit" disabled={isSubmitting || toastOpen} >
                                        Submit
                                    </button>
                                </div>
                                <Snackbar 
                                    open={toastOpen}
                                    autoHideDuration={10000}
                                    onClose={() => setToastOpen(false)}
                                    message={toastMessage}
                                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                />
                        </Form>
                    )}}
                </Formik> 
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({
    updatePassword: (email, newPassword, forgotPasswordToken) => dispatch(updatePassword(email, newPassword, forgotPasswordToken))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)