import React from 'react'

import './error.page.css'

const ErrorPage = () => {
  return (
    <div className='error-page'>
      <div className='error-item'>
        <i className='icon icon-warning error-item_icon' />
        <span className='error-item_message'>
            Page Not Found!
          </span>
      </div>
    </div>
  )
}

export default ErrorPage