import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Title, LoadingMask } from '../../components'
import { fetchPolicyByType, FETCHING } from '../../actions'
import './policies.page.css'
import { last } from 'lodash'

class PolicyPage extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      docType: last(window.location.pathname.split('/')),
      docBody: ''
    }
  }

  loadPolicy () {
    const { fetchPolicyByType } = this.props
    const page = last(window.location.pathname.split('/'))

    fetchPolicyByType(this.state.docType).then((record) => {
      const data = record.payload.data

      this.setState({
        docBody: (data && data.body) || '',
        docType: page
      })
    })
  }


  componentDidMount (prevProps) {
    this.loadPolicy()
  }

  componentDidUpdate (prevProps) {
    const page = last(window.location.pathname.split('/'))

    // This condition ensures that we don't make more requests than needed
    if(page !== this.state.docType && page !== prevProps.page && prevProps.fetchingStatus !== FETCHING){
      this.loadPolicy()
    }
  }


  createMarkup (docBody) {
    return { __html: docBody }
  }

  render () {
    const { fetchingStatus } = this.props
    const { docBody, docType } = this.state

    return (
      <div className='privacy-page'>
          {fetchingStatus === FETCHING ? (
            <LoadingMask
            />
          ) : (
            <Title className={'centered'}>
              {docType === 'termsofservice' ? 'Terms Of Service' : 'Privacy Policy'}
            </Title>
          )}
          <div
            className={'doc-body-container'}
            dangerouslySetInnerHTML={this.createMarkup(docBody)}
          />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { policy } = state

  return {
    fetchingStatus: policy.status
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchPolicyByType: (docType) => dispatch(fetchPolicyByType(docType))
})

export default connect(mapStateToProps, mapDispatchToProps)(PolicyPage)
