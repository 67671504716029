import ReactGA from 'react-ga4'

/**
 * Will log user actions with google analytics
 *
 * @param type
 * @param value
 * @param user
 */
export const logUserActions = (type, value, user) => {
  const types = {
    source: {
      category: 'User',
      action: 'Clicked on source link'
    },
    detail: {
      category: 'User',
      action: 'Open Article Detail',
      label: value || ''
    },
    search: {
      category: 'User',
      action: 'Search Articles',
      label: value
    },
    shareArticle: {
      category: 'User',
      action: 'Share Article',
      label: value
    },
    shareTopic: {
      category: 'User',
      action: 'Share Topic',
      label: value
    },
    bookmarkArticle: {
      category: 'User',
      action: 'Bookmarked Article'
    }
  }

  const params = types[type]

  if (user && user._id) {
    params.label += `; By user: ${user._id}`
  }

  ReactGA.event(params)
}
