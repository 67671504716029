/**
 * Global Search Action Type Constants
 * @type {string}
 */
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY'
export const CLEAR_SEARCH_QUERY = 'CLEAR_SEARCH_QUERY'

/**
 * Dispatch UPDATE_SEARCH_QUERY
 */
export const updateSearchQuery = (query) => {
  window.scrollTo(0, 0)

  return ({
    type: UPDATE_SEARCH_QUERY,
    payload: query || ''
  })
}

/**
 * Dispatch CLEAR_SEARCH_QUERY
 */
export const clearSearchQuery = () => {
  window.scrollTo(0, 0)

  return ({
    type: CLEAR_SEARCH_QUERY
  })
}
