import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FacebookShareButton, TwitterShareButton, RedditShareButton } from 'react-share'
import { FacebookIcon, XIcon, RedditIcon } from 'react-share'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import config from '../../config'
import './share.component.css'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const generateShareText = (type, title, content) => {
  let text = ''
  if (type === 'article') {
    text = `${title}\n\n`
    if (content) {
      content = content.replace(/[^a-zA-Z :!?'",`’]/g, "")
      text += `${content}...\n\n`
    }
  } else {
    text = `Topic: ${title}\n\n`
  }

  return text
}

class ShareComponent extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isVisible: false
    }
  }


  render () {
    const { url, isVisible, handleShareClose, type, title, content } = this.props
    const shareUrl = url.indexOf('://') === -1 ?`${config.BASE_URL}/${url}` : url
    const shareText = generateShareText(type, title, content)

    return (
      <Dialog
        id={'share-dialog'}
        open={isVisible}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleShareClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        classes={{ paper: 'share-modal' }}
      >
        <DialogContent
          className={'share-modal-content'}
        >
          <h2 className={'share-modal-title'}>share {type}</h2>
          <IconButton
            aria-label='Close'
            className={'share-modal-close'}
            onClick={handleShareClose}
            size="large">
            <CloseIcon />
          </IconButton>
          <DialogContentText id='alert-dialog-slide-description' className={'share-modal-body'}>
            <FacebookShareButton
              url={shareUrl}
            >
              <FacebookIcon
                size={40}
                round
              />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={shareText}
            >
              <XIcon
                size={40}
                round
              />
            </TwitterShareButton>
            <RedditShareButton
              url={shareUrl}
              title={title}
            >
              <RedditIcon
                size={40}
                round
              />
            </RedditShareButton>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}


ShareComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  url: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string
}

ShareComponent.defaultProps = {
  isVisible: false
}

export default ShareComponent
