import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import './welcome.page.css'
import { Button } from '../../components'
import AuthenticationContainer from '../../containers/authentication/authentication.container'

class WelcomePage extends PureComponent {
	constructor (props) {
		super(props)

		this.state = {
			loginFormVisible: false
		}

		this.toggleLoginForm = this.toggleLoginForm.bind(this)
	}

	toggleLoginForm () {
		this.setState({
			loginFormVisible: !this.state.loginFormVisible
		})
	}

	render () {
		const { loginFormVisible } = this.state
		const { auth } = this.props

		return (
			<div className='welcome-page'>
				<h1>All your favorite topics, in one place</h1>
				<h2>Sign in to see all your subscriptions here</h2>
				<img src='https://storage.googleapis.com/tattle-public-images/site/topicicons.png' alt='topic icons' />
				<Button
					label={'Sign in'}
					className={'sign-in_btn'}
					onClick={this.toggleLoginForm}
					/>

				<AuthenticationContainer
					loginFormVisible={loginFormVisible}
					onClose={this.toggleLoginForm}
					formType={'login'}
					auth={auth}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
 return {}
}

const mapDispatchToProps = (dispatch) => ({
	// todo
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage)