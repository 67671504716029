import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../../config'
import { GET } from '../../index'

/**
 * Topic Action Type Constants
 * @type {string}
 */
export const GET_TOPIC_LIST_REQUEST = 'GET_TOPIC_LIST_REQUEST'
export const GET_TOPIC_LIST_SUCCESS = 'GET_TOPIC_LIST_SUCCESS'
export const GET_TOPIC_LIST_FAILURE = 'GET_TOPIC_LIST_FAILURE'

/**
 * Dispatch GET_TOPIC_LIST_REQUEST
 */
export const fetchTopicsByCategoryId = (id) => ({
  [RSAA]: {
    endpoint: `${API_URL}/public/topic/category/${id}`,
    method: GET,
    headers: HEADERS,
    types: [
      GET_TOPIC_LIST_REQUEST,
      GET_TOPIC_LIST_SUCCESS,
      GET_TOPIC_LIST_FAILURE
    ]
  }
})
