import {
  GET_ARTICLE_REQUEST,
  GET_ARTICLE_SUCCESS,
  GET_FIRSTFETCH_ARTICLE_SUCCESS,
  SEARCH_ARTICLE_SUCCESS,
  GET_ARTICLE_TOPIC_SUCCESS,
  GET_ARTICLE_FAILURE,
  GET_SUBSCRIBED_ARTICLE_REQUEST,
  GET_SUBSCRIBED_ARTICLE_SUCCESS,
  GET_SUBSCRIBED_ARTICLE_FAILURE,
  GET_SUBSCRIBED_SEARCH_ARTICLE_SUCCESS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions'
import { uniqBy } from 'lodash'

const initialState = {
  data: [],
  status: IDLE
}

const list = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE_REQUEST:
    case GET_SUBSCRIBED_ARTICLE_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_ARTICLE_SUCCESS:
    case GET_SUBSCRIBED_ARTICLE_SUCCESS:
      return {
        ...state,
        data: uniqBy([...state.data, ...action.payload.data], '_id'),
        status: FETCHED
      }
    case SEARCH_ARTICLE_SUCCESS:
    case GET_ARTICLE_TOPIC_SUCCESS:
    case GET_SUBSCRIBED_SEARCH_ARTICLE_SUCCESS:
      return {
        ...state,
        data: uniqBy([...state.data, ...action.payload.data], '_id'),
        status: FETCHED
      }
    case GET_FIRSTFETCH_ARTICLE_SUCCESS:
      if(action.payload.success === false){
        return {
          ...state,
          data: null,
          status: FETCHED
        }
      }else{
        return {
          ...state,
          data: uniqBy([...action.payload.data], '_id'),
          status: FETCHED
        }
      }
    case GET_ARTICLE_FAILURE:
    case GET_SUBSCRIBED_ARTICLE_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default list
