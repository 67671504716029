import React, { PureComponent } from 'react'
import { LoadingMask } from '../../../components'
import { connect } from 'react-redux'
import { authenticateWithDiscord } from '../../../actions'

class DiscordOauth2Page extends PureComponent {
    componentDidMount() {
        const params = new URL(window.location.toString()).searchParams
        const code = params.get('code')

        if(!code) {
            window.location = '/trending'
            return;
        }

        const { authenticateWithDiscord } = this.props

        authenticateWithDiscord(code).then(() => {
            window.location = '/home'
        })
    }
  render() {
    return (
      <LoadingMask />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  authenticateWithDiscord: (code) => dispatch(authenticateWithDiscord(code))
})

export default connect(null, mapDispatchToProps)(DiscordOauth2Page)