import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../config'
import { GET } from '../index'
import queryString from 'query-string'

/**
 * Article Action Type Constants
 * @type {string}
 */
export const GET_ARTICLE_REQUEST = 'GET_ARTICLE_REQUEST'
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS'
export const GET_FIRSTFETCH_ARTICLE_SUCCESS = 'GET_FIRSTFETCH_ARTICLE_SUCCESS'
export const SEARCH_ARTICLE_SUCCESS = 'SEARCH_ARTICLE_SUCCESS'
export const GET_ARTICLE_TOPIC_SUCCESS = 'GET_ARTICLE_TOPIC_SUCCESS'
export const GET_ARTICLE_FAILURE = 'GET_ARTICLE_FAILURE'

export const GET_SUBSCRIBED_ARTICLE_REQUEST = 'GET_SUBSCRIBED_ARTICLE_REQUEST'
export const GET_SUBSCRIBED_ARTICLE_SUCCESS = 'GET_SUBSCRIBED_ARTICLE_SUCCESS'
export const GET_SUBSCRIBED_ARTICLE_FAILURE = 'GET_SUBSCRIBED_ARTICLE_FAILURE'
export const GET_SUBSCRIBED_SEARCH_ARTICLE_SUCCESS = 'GET_SUBSCRIBED_SEARCH_ARTICLE_SUCCESS'

export const GET_ARTICLE_DETAIL_REQUEST = 'GET_ARTICLE_DETAIL_REQUEST'
export const GET_ARTICLE_DETAIL_SUCCESS = 'GET_ARTICLE_DETAIL_SUCCESS'
export const GET_ARTICLE_DETAIL_FAILURE = 'GET_ARTICLE_DETAIL_FAILURE'

/**
 * Dispatch GET_ARTICLE_REQUEST (by topic _id)
 */
export const fetchArticles = (params, auth) => {
  const query = queryString.stringify(params)
  let PATH = 'public'
  let headers = HEADERS

  if (auth && auth.token) {
    PATH = 'private'
    headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.token}` } }
  }

  const type = (params.isFirstLoad)? [
    GET_ARTICLE_REQUEST,
    GET_FIRSTFETCH_ARTICLE_SUCCESS,
    GET_ARTICLE_FAILURE
  ]: [
    GET_ARTICLE_REQUEST,
    GET_ARTICLE_SUCCESS,
    GET_ARTICLE_FAILURE
  ]

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/${PATH}/article?${query}`,
      method: GET,
      headers: headers,
      types: type
    }
  })
}

/**
 * Dispatch GET_ARTICLE_REQUEST (by topic _id)
 */
export const fetchArticlesByTopic = (topicId, params, auth) => {
  const query = queryString.stringify(params)
  let PATH = 'public'
  let headers = HEADERS

  if (auth && auth.token) {
    PATH = 'private'
    headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.token}` } }
  }

  const type = (params.isFirstLoad)? [
    GET_ARTICLE_REQUEST,
    GET_FIRSTFETCH_ARTICLE_SUCCESS,
    GET_ARTICLE_FAILURE
  ]: [
    GET_ARTICLE_REQUEST,
    GET_ARTICLE_TOPIC_SUCCESS,
    GET_ARTICLE_FAILURE
  ]

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/${PATH}/article/topic/${topicId}?${query}`,
      method: GET,
      headers: headers,
      types:type
    }
  })
}

/**
 *
 */
export const fetchArticlesByCategoryName = (params, isFirstLoad) => {
  const query = queryString.stringify({
    search: params.search,
    page: params.page,
    offset: params.offset
  })
  const catName = params.category
  let PATH = 'public'
  let headers = HEADERS
  let types

  if (isFirstLoad) {
    types = [
      GET_ARTICLE_REQUEST,
      GET_FIRSTFETCH_ARTICLE_SUCCESS,
      GET_ARTICLE_FAILURE
    ]
  } else {
    types = [
      GET_ARTICLE_REQUEST,
      GET_ARTICLE_SUCCESS,
      GET_ARTICLE_FAILURE
    ]
  }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/${PATH}/article/cat_name/${catName}?${query}`,
      method: GET,
      headers: headers,
      types: types
    }
  })
}

export const fetchSubscribedArticles = (params, auth) => {
  const query = queryString.stringify(params)
  let headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.token}` } }

  const type = (params.isFirstLoad)? [
    GET_SUBSCRIBED_ARTICLE_REQUEST,
    GET_FIRSTFETCH_ARTICLE_SUCCESS,
    GET_SUBSCRIBED_ARTICLE_FAILURE
  ]: [
    GET_SUBSCRIBED_ARTICLE_REQUEST,
    GET_SUBSCRIBED_ARTICLE_SUCCESS,
    GET_SUBSCRIBED_ARTICLE_FAILURE
  ]

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/article/subscribed?${query}`,
      method: GET,
      headers: headers,
      types: type
    }
  })
}

export const fetchSubscribedArticlesByQuery = (params, auth) => {
  const query = queryString.stringify({
    search: params.search,
    page: params.page,
    offset: params.offset,
    feed_type: params.feed_type
  })
  let headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.token}` } }

  const type = (params.isFirstLoad)? [
    GET_SUBSCRIBED_ARTICLE_REQUEST,
    GET_FIRSTFETCH_ARTICLE_SUCCESS,
    GET_SUBSCRIBED_ARTICLE_FAILURE
  ]: [
    GET_SUBSCRIBED_ARTICLE_REQUEST,
    GET_SUBSCRIBED_SEARCH_ARTICLE_SUCCESS,
    GET_SUBSCRIBED_ARTICLE_FAILURE
  ]

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/article/subscribed?${query}`,
      method: GET,
      headers: headers,
      types: type
    }
  })
}

export const fetchArticleDetail = (id) => {
  return ({
    [RSAA]: {
      endpoint: `${API_URL}/public/article/${id}`,
      method: GET,
      headers: HEADERS,
      types: [
        GET_ARTICLE_DETAIL_REQUEST,
        GET_ARTICLE_DETAIL_SUCCESS,
        GET_ARTICLE_DETAIL_FAILURE
      ]
    }
  })
}
