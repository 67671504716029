import './jumpToTop.component.css'

import { Fragment, PureComponent } from 'react'

class JumpToTop extends PureComponent {

    constructor (props) {
        super(props)

        this.state = {
            show: false
        }

        this.handleScroll = this.handleScroll.bind(this)
    }

    handleScroll (event) {
        this.setState({
          show: event.target.scrollTop > 100? true : false
        })
      }
    
      componentDidMount () {
        window.addEventListener('scroll', this.handleScroll, true)
      }
    
      componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll, true)
      }


    render () {
        return (
            <Fragment>
                {
                    this.state.show?
                        <a className='jump-to-top-link' href={`#top-of-page`}>
                            <svg className="jump-to-top-arrow" width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.72135e-05L-1.23266e-07 5.99991L1.41 7.40991Z" fill="white"/>
                            </svg>
                            <span className="jump-to-top-text">
                                Jump To Top
                            </span>
                        </a>
                    :
                        null
                }
            </Fragment>
        )
    }
}

export default JumpToTop
