const production = {
  API_URL: 'https://tattle-web-api-bc2bb3614633.herokuapp.com/api',
  BASE_URL: 'https://www.tattlenews.com',
  HEADERS: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'api-key': 'd0c0f218-3793-47bc-8156-48dd940274dd',
    Authorization: ''
  },
  STRIPE_BUY_URL:'https://buy.stripe.com/dR68A982i56u6oE288',
  STRIPE_CUSTOMER_PORTAL:'https://billing.stripe.com/p/login/aEUcP86qNe2v48g144',
  GOOGLE_API_KEY: 'G-CMBXR1NSKH',
  DISCORD_OAUTH2_URL: 'https://discord.com/oauth2/authorize?client_id=671176256313622548&response_type=code&redirect_uri=https%3A%2F%2Fwww.tattlenews.com%2Foauth2%2Fdiscord&scope=identify+email',
  APP_VERSION: '1.5.0'

}

const develop = {
  API_URL: 'https://tattle-web-api-dev-6135b2d06f87.herokuapp.com/api',
  // API_URL: 'http://localhost:3001/api',
  BASE_URL: 'https://develop.tattlenews.com',
  HEADERS: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'api-key': '5433eb58-ef7c-4736-842d-84a0c537c6dd',
    Authorization: ''
  },
  STRIPE_BUY_URL:'https://buy.stripe.com/test_14k15Z4km78u8U0bII',
  STRIPE_CUSTOMER_PORTAL:'https://billing.stripe.com/p/login/test_dR68z0aH27XW3JK7ss',
  GOOGLE_API_KEY: 'G-7Z0XQZ9QJ6',
  DISCORD_OAUTH2_URL: 'https://discord.com/oauth2/authorize?client_id=759597667918807060&response_type=code&redirect_uri=https%3A%2F%2Fdevelop.tattlenews.com%2Foauth2%2Fdiscord&scope=identify+email',
  APP_VERSION: '1.5.0'
  // For local dev, uncomment below
  // DISCORD_OAUTH2_URL: 'https://discord.com/oauth2/authorize?client_id=759597667918807060&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foauth2%2Fdiscord&scope=identify+email'
}

switch (process.env.REACT_APP_ENV) {
  case 'production':
    module.exports = production
    break;
  default:
    module.exports = develop
}
