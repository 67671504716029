import React from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../utils'
import './loadingMask.component.css'

const LoadingMask = (props) => {
  const { className, theme, isFixed, includeSpinner, includeOverlay, overlayOpacity, overlayColor } = props

  const baseClassName = getClassName('loading-mask', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `loading-mask--${theme}` },
    { condition: isFixed, trueClassName: 'loading-mask--fixed' }
  ])

  const maskStyles = {
    backgroundColor: overlayColor,
    opacity: overlayOpacity
  }

  return (
    <div className={baseClassName}>
      {includeSpinner && (
        <div className='loading-mask_spinner' />
      )}
      {includeOverlay && (
        <div className='loading-mask_overlay' style={maskStyles} />
      )}
    </div>
  )
}

LoadingMask.propTypes = {

  /**
   * A custom className to add to the component
   */
  className: PropTypes.string,

  /**
   * Used to provide the LoadingMask component with a specific theme
   */
  theme: PropTypes.oneOf(['light', 'dark']),

  /**
   * Used to determine whether or not the loader renders above all window content using position: fixed
   * By default,
   */
  fixed: PropTypes.bool,

  /**
   * Used to determine whether or not a mask container is rendered alongside the LoadingMask
   */
  includeSpinner: PropTypes.bool,

  /**
   * Used to determine whether or not a mask container is rendered alongside the LoadingMask
   */
  includeOverlay: PropTypes.bool,

  /**
   * User to provide custom styling for the overlay within the LoadingMask component
   */
  overlayOpacity: PropTypes.number,
  overlayColor: PropTypes.string
}

LoadingMask.defaultProps = {
  theme: 'dark',
  fixed: false,
  includeSpinner: true,
  includeOverlay: true
}

export default LoadingMask
