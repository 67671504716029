import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import TattleLink from '../link/link.component'
import './categoryTopicList.component.css'

class CategoryTopicList extends PureComponent {
	/**
	 * Constructor
	 * @param props
	 */
	constructor (props) {
		super(props)

		this.state = {}
	}

	render () {
		const { topics, categoryName, category } = this.props
		const noData = !topics || !topics.length

		return (
			<div className='category-topics-list'>
				<div className='category-name'>
					<TattleLink
						className='btn-explore-all'
						to={`/explore/${category}`}
						>
						<span className='category-name-label'>{categoryName}</span>
						<span className='explore-all-label'>Explore All<i className='icon icon-arrow_forward explore-all-icon' /></span>
					</TattleLink>
					<i className='icon icon-arrow_forward show-more-icon' />
				</div>
				<ul className='topic-list'>
					{!noData ? topics.map((topic) => {
						const { _id, seo_name, label, panel, active } = topic

						let li

						if (active) {
							li = (
								<li key={`topic_item--${_id}`} className='topic_item'>
									<TattleLink className='topic_link' to={`/topic/${seo_name}`}>
										<img
											alt={label}
											className='topic_thumbnail'
											src={panel}
											// onClick={() => onTopicSelect && onTopicSelect(topic)}
										/>
										<label className='topic_tooltip'>{label}</label>
									</TattleLink>
								</li>
							)
						} else {
							li = (
								<li key={`topic_item--${_id}`} className='topic_item coming-soon'>
									<img
										alt={label}
										className='topic_thumbnail'
										src={panel}
									/>
									<div className='topic_item--shadow' />
									<label className='topic_tooltip'>{label}</label>
								</li>
							)
						}

						return li
					}) : (
						<li className='topic_item topic_item--no-data'>No topics available for this category.</li>
					)}
				</ul>
			</div>
		)

	}
}

CategoryTopicList.propTypes = {
	topics: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		seo_name: PropTypes.string,
		panel: PropTypes.string,
		active: PropTypes.bool
	})),
	categoryName: PropTypes.string
}

CategoryTopicList.defaultProps = {
	topics: []
}

export default CategoryTopicList