import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button } from '../../components'
import { fetchArticleDetail, FETCHING, FAILED } from '../../actions'
import { LoadingMask, ShareComponent } from '../../components'
import { last } from 'lodash'
import { logUserActions, getLnk } from '../../utils'
import ErrorPage from '../../pages/error/error.page'
import './article.container.css'

class ArticleContainer extends PureComponent {
	constructor (props) {
		super(props)

		this.state = {
			lastPage: '',
			shareOpen: false,
			sharingOptions: {
				url: '',
				title: '',
				content: ''
			},
			history: props.history
		}

		this.onBackButtonClick = this.onBackButtonClick.bind(this, props.history)
		this.onShareButtonClick = this.onShareButtonClick.bind(this)
		this.handleShareClose = this.handleShareClose.bind(this)
	}

	componentDidMount () {
		const { fetchArticleDetail } = this.props
		const seoName = last(window.location.pathname.split('/'))

		fetchArticleDetail(seoName)
			.then((record) => {
				const data = record.payload.data
				const title = data.title

				// TODO: create a shared component to take care of this
				document.title = title
			})
			.catch((e) => {
				// console.log('err', e)
			})

	}

	componentWillUnmount () {
		document.title = 'Tattle - A new way to connect to your interests!'
	}

	onBackButtonClick () {
		const { history } = this.state
		let path = window.location.pathname.split('/')
		path.pop()

		if (window.history.length <= 2) {
			window.location = `${window.location.origin}${path.join('/')}`
		} else {
			history.back()
		}
	}

	onShareButtonClick (title) {
		const url = (`${window.location.href}`).split('topic/')[1]
		const { record } = this.props
		const { content_text } = record

		this.setState({
			shareOpen: !this.state.shareOpen,
			sharingOptions: {
				title: title,
				url: `topic/${url}`,
				content: content_text
			}
		})
	}

	handleShareClose () {
		const { sharingOptions } = this.state
		const article = sharingOptions.title

		logUserActions('shareArticle', article)

		this.setState({
			shareOpen: false,
			sharingOptions: {
				title: '',
				url: ''
			}
		})
	}

	render () {
		const { shareOpen, sharingOptions } = this.state
		const { record, articleStatus } = this.props
		if(articleStatus === FAILED){
			window.location.reload();
			this.state.history.back();
		}
		const { feed_link, title } = record
		const link = getLnk(record)

		let feed_title = (feed_link && feed_link.title) || ''
		let feed_type = record.feed_type || 'feed'
		// TODO: Make this section more readable
		return (
			<div className={'article-detail'}>
				{articleStatus === FAILED ? (
					<div className={'article-detail_header'}>
						<Button
							theme="transparent"
							icon="arrow_back"
							className="header_back-button"
							onClick={this.onBackButtonClick}
						/>
						<div className={'header_feed-title'}>
							<h3>Article not Found!</h3>
						</div>
						<div className={'header_action3'}> </div>
					</div>
				) : (
				<div className={'article-detail_header'}>
					<Button
						theme="transparent"
						icon="arrow_back"
						className="header_back-button"
						onClick={this.onBackButtonClick}
					/>
					<div className={'header_feed-title'}>
						<h3>{feed_title}</h3>
					</div>
					<div className={'header_action3'}>
						<Button
							linkTo={link}
							theme="transparent"
							icon="launchopen_in_new"
							className="header_link-button"
						/>
						<Button
							theme="transparent"
							icon="share"
							className="header_share-button"
							onClick={() => this.onShareButtonClick(title)}
						/>
					</div>
				</div>
				)}
				{articleStatus === FETCHING ? (
					<LoadingMask />
				) : (
					articleStatus === FAILED ? (
						<ErrorPage />
					) : (
					(record && link) ?
						(feed_type === 'feed') ?
							<iframe
								src={link}
								width={'100%'}
								frameBorder="0"
								title={title || ''}
								allow={'autoplay; encrypted-media'}
								allowFullScreen
								scrolling="yes"
								className={'article-frame'}
							/>
							:
							<iframe
								width={'100%'}
								className={'article-frame'}
								src={('https://www.youtube.com/embed/' + (link).split('?v=')[1] + '?autoplay=1')}
								frameBorder="0"
								title={title || ''}
								allowFullScreen
							/>
						: ''
				))}
				<ShareComponent
					isVisible={shareOpen}
					handleShareClose={this.handleShareClose}
					type={'article'}
					{...sharingOptions}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { article } = state

	return {
		record: article.item.record,
		articleStatus: article.item.status
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchArticleDetail: (id) => dispatch(fetchArticleDetail(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleContainer)