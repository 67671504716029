import { PureComponent } from "react";
import { connect } from 'react-redux'
import { FETCHED, FETCHING, FAILED, IDLE, sendVerificationEmail, clearEmailVerification } from "../../actions";
import "./emailVerification.component.css"
import Snackbar from '@mui/material/Snackbar';

class EmailVerification extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            toastVisible: false
        }

        this.verifyEmailHandler = this.verifyEmailHandler.bind(this);
    }

    verifyEmailHandler = async () => {
        this.props.sendVerificationEmail(this.props.user.username, this.props.user.display_name, this.props.auth);
    }

    render() { 
        let toastText = '';
        switch (this.props.sendVerificationEmailStatus) {
            case IDLE:
                break;
            case FETCHING:
                break;
            case FETCHED:
                toastText = "Email sent to " + this.props.user.username;
                break;
            case FAILED:
                toastText = this.props.sendVerificationEmailError;
                break;
            default:
                toastText = '';
                break;
        }

        this.setState({
            toastVisible: toastText !== ''
        })

        if(Object.keys(this.props.user).length === 0 || this.props.user.isEmailVerified){
            return null;
        }
        
        return (
            <div className="email-verification_container">
                <span>Your email is not verified. </span>
                <button href="#" onClick={this.verifyEmailHandler}>
                    Verify email.
                </button>
                <Snackbar
                    anchorOrigin ={{vertical: "top", horizontal: "center"}} 
                    open={this.state.toastVisible}  
                    autoHideDuration= {10000} 
                    onClose={
                        () => {
                            this.setState({toastVisible: false});
                            this.props.clearEmailVerification();
                        }
                    } 
                    message={toastText} 
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { profile } = state
    const { user, sendVerificationEmail } = profile
    return {
        user,
        sendVerificationEmailStatus: sendVerificationEmail?.status,
        sendVerificationEmailError: sendVerificationEmail?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendVerificationEmail: (email, displayName, auth) => dispatch(sendVerificationEmail(email, displayName, auth)),
        clearEmailVerification: () => dispatch(clearEmailVerification())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);