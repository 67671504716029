import React from 'react'
import PropTypes from 'prop-types'
import { Button, Field, TattleLink } from '../../index'
import '../authentication.component.css'
import OAuthLoginButton from '../OAuthLoginButton/OAuthLoginButton.component'
/**
 * Login Form component
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LoginForm = (props) => {
  const {
    username,
    password,
    authStatus,
    authMessage,
    onLoginClick,
    handleLoginFieldChange,
    handleViewSwitch
  } = props

  const onKeyDown = () => {
    if (username && password) {
      onLoginClick && onLoginClick({ username, password })
    }
  }

  return (
    <section className='login-modal_inner'>
      <h3 className='login-modal_title'>Sign In</h3>
      <div className='login-modal_disclaimer'>By continuing, you agree to our <TattleLink to="/termsofservice">Terms of Service</TattleLink> and <TattleLink to="privacypolicy">Privacy Policy</TattleLink></div>
      {authStatus ? (<p className={'error_message'}>{authMessage}</p>) : ('')}
      {/* <OAuthLoginButton oAuthProvider="apple" />
      <OAuthLoginButton oAuthProvider="google" /> */}
      <OAuthLoginButton oAuthProvider="discord" />
      <div className="login-divider">
          <span>OR</span>
      </div>
      <div className='login-modal_form'>
        <Field
          size='full'
          id='login_username'
          type='email'
          name='username'
          forceValue={username}
          placeholder='Username'
          onChange={handleLoginFieldChange}
          onKeyDown={onKeyDown}
        />
        <Field
          size='full'
          type='password'
          id='login_password'
          name='password'
          forceValue={password}
          placeholder='Password'
          onChange={handleLoginFieldChange}
          onKeyDown={onKeyDown}
        />
        <Button
          label='Sign In'
          onClick={() => onLoginClick({ username, password })}
        />

      </div>

      <div className='login-modal_message'>
        <span>New to Tattle? </span>
        <span className='link' onClick={() => handleViewSwitch('registration')}>Sign up</span>
        <br/>
        <span className='link' onClick={() => handleViewSwitch('forgotPassword')}>Forgot Password?</span>
      </div>
    </section>
  )
}

LoginForm.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  onLoginClick: PropTypes.func.isRequired,
  handleLoginFieldChange: PropTypes.func.isRequired,
  handleViewSwitch: PropTypes.func.isRequired
}

export default LoginForm
