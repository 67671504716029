import { RSAA } from 'redux-api-middleware'
import { API_URL, HEADERS } from '../../config'
import { GET, POST, DELETE } from '../index'
import queryString from 'query-string'

/**
 * Bookmark Action Type Constants
 * @type {string}
 */
export const GET_BOOKMARK_REQUEST = 'GET_BOOKMARK_REQUEST'
export const GET_BOOKMARK_SUCCESS = 'GET_BOOKMARK_SUCCESS'
export const GET_BOOKMARK_FAILURE = 'GET_BOOKMARK_FAILURE'

export const POST_BOOKMARK_REQUEST = 'POST_BOOKMARK_REQUEST'
export const POST_BOOKMARK_SUCCESS = 'POST_BOOKMARK_SUCCESS'
export const POST_BOOKMARK_FAILURE = 'POST_BOOKMARK_FAILURE'

export const DELETE_BOOKMARK_REQUEST = 'DELETE_BOOKMARK_REQUEST'
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS'
export const DELETE_BOOKMARK_FAILURE = 'DELETE_BOOKMARK_FAILURE'

export const fetchBookmarks = (params, auth) => {
  const query = queryString.stringify(params)
  let headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.token}` } }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/bookmark?${query}`,
      method: GET,
      headers: headers,
      types: [
        GET_BOOKMARK_REQUEST,
        GET_BOOKMARK_SUCCESS,
        GET_BOOKMARK_FAILURE
      ]
    }
  })
}

export const createBookmark = (body, auth) => {
  let headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.token}` } }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/bookmark`,
      method: POST,
      headers: headers,
      body: JSON.stringify(body),
      types: [
        POST_BOOKMARK_REQUEST,
        POST_BOOKMARK_SUCCESS,
        POST_BOOKMARK_FAILURE
      ]
    }
  })
}

export const deleteBookmark = (id, auth) => {
  let headers = { ...HEADERS, ...{ Authorization: `Bearer ${auth.token}` } }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/private/bookmark/${id}`,
      method: DELETE,
      headers: headers,
      types: [
        DELETE_BOOKMARK_REQUEST,
        DELETE_BOOKMARK_SUCCESS,
        DELETE_BOOKMARK_FAILURE
      ]
    }
  })
}