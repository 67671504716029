import React, { Fragment, PureComponent } from 'react'
import { TattleLink } from '../../components'

import AuthenticationContainer from '../../containers/authentication/authentication.container'

import { Button } from '@mui/material'

import './bot.container.css'

class FaqQuestion extends PureComponent {
	constructor ({question, answer}) {
		super();

		this.state = {
			showAnswer: false
		}
		this.question = question;
		this.answer = answer;

		this.toggleShowAnswer = this.toggleShowAnswer.bind(this);
	}

	toggleShowAnswer = () => {
		this.setState({
			showAnswer: !this.state.showAnswer
		})
	}

	render () {
		return (
			<div className="bot-faqs-question-container">
				<div className="bot-faqs-question-clickable" onClick={this.toggleShowAnswer}>
					<div className="bot-faqs-question">{this.question}</div>
					<div className="bot-faqs-arrow" >						
					{this.state.showAnswer?
						<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.72135e-05L-1.23266e-07 5.99991L1.41 7.40991Z" fill="white"/>
						</svg>
					:
						<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z" fill="white"/>
						</svg>
					}
					</div>
				</div>

				{this.state.showAnswer?
					<div className='bot-faqs-answer'>{this.answer}</div>
				:
					null
				}
			</div>
		);
	}
}

class BotContainer extends PureComponent {
	FAQs = {
		"What is Tattle Bot?": "Tattle is a new way of receiving topic specific news & content right to your Discord server. Add topics from a number of different categories such as League of Legends, Minecraft, NBA, Bitcoin & much more!",
		"What is included with Tattle Pro?": "Get access to hundreds of topics with no subscription limit, play videos directly from Discord, and access  any exclusive topic.",
		"Can I try Tattle Bot for free?": "Yes! You can try Tattle for 1 month with no credit card required on your server of choice. After the trial ends you will stop receiving updates and will need to upgrade to Tattle Pro for US$4.99/month.",
		"Have other questions or concerns?": <span>You can reach us on our Discord support server and talk to any one of our support staff.
		Join <TattleLink to="https://discord.com/invite/pvQ4Bdq">here</TattleLink>.</span>
	};

	constructor ({auth}) {
		super();

		this.state = {
			auth: auth,
			loginFormVisible: false
		};

		this.getTattlePro = this.getTattlePro.bind(this);
		this.toggleLoginForm = this.toggleLoginForm.bind(this);
	}

	getTattlePro() {
		if(this.state.auth.isLoggedIn()) {
			window.location = '/settings/membership'
		}else{
			this.toggleLoginForm();
		}
	}

	toggleLoginForm = () => {
		this.setState({
			...this.state,
			loginFormVisible: !this.state.loginFormVisible
		})
	}

	render () {
		
		return (
			<Fragment>
				<AuthenticationContainer
					loginFormVisible={this.state.loginFormVisible}
					onClose={this.toggleLoginForm}
					formType={'login'}
					auth={this.state.auth}
					redirect={"/settings/membership"}
				/>
				<div className='bot-container'>
					<div className='top-image-container'>
						<img alt='Tattlenews logo' src='https://storage.googleapis.com/tattle-public-images/site/tattlelibrarygradientdown.jpg' className='bot-library-image' />
						<div className="header-overlay-text">Live news on Discord.</div>
					</div>
					<div className='bot-subscriptions-container'>
						<div className="bot-subscription-container free-container">
							<div className="bot-subscription-title" >
								Free
							</div>
							<div className="bot-subscription-description">
								<ul className="bot-subscription-features">
									<li> Access to over 200 topics</li>
									<li>3 Subscription limit</li>
								</ul>
							</div>
							<div className="bot-subscription-select">
								<div>Try Tattle bot for free!</div>
								<TattleLink className="bot-subscription-button free-button" to="https://top.gg/bot/671176256313622548">
									Add to server
								</TattleLink>
							</div>
						</div>
						<div className='bot-subscription-spacer'></div>
						<div className="bot-subscription-container pro-container">
							<div className="bot-subscription-title">
								Pro<img className="bot-subscription-pro-img" src="https://storage.googleapis.com/tattle-public-images/icon/baseline_auto_awesome_white_24dp.png" alt="pro" />
							</div>
							<div className="bot-subscription-description">
								<ul className="bot-subscription-features">
									<li> Access to over 200 topics</li>
									<li>No subscription limit</li>
									<li>Video playback on Discord</li>
								</ul>
							</div>
							<div className="bot-subscription-select">
								<div>USD $4.99 Recurring monthly</div>
								<Button className="bot-subscription-button pro-button" variant='contained' label="text" onClick={this.getTattlePro}>Get Tattle Pro</Button>
							</div>
						</div>
					</div>
					<div className="bot-pro-features-container">
						<div className="bot-pro-features-title">
							<span>PRO FEATURES</span>
						</div>
						<div className="down-arrow" onClick={this.scrollToFeatures}>
							<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z" fill="white"/>
							</svg>
						</div>
						<div className='bot-pro-features-image-container'>
							<img alt='Tattlenews logo' src='https://storage.googleapis.com/tattle-public-images/site/tattlepropanel1.png' className='bot-pro-features-image' />
							<div className="pro-features-overlay-text text-left">No subscription limit</div>
							<div className="pro-features-overlay-subtext text-left">Add as many topics as you'd like.</div>
						</div>
						<div className='bot-pro-features-image-container'>
							<img alt='Tattlenews logo' src='https://storage.googleapis.com/tattle-public-images/site/tattlepropanel2.png' className='bot-pro-features-image' />
							<div className="pro-features-overlay-text text-right">Video Playback</div>
							<div className="pro-features-overlay-subtext text-right"><span style={{textAlign: 'left'}}>Play videos directly from Discord <br/>on desktop.</span></div>
						</div>
					</div>
					<div className="bot-faqs-container">
						<div className="bot-pro-features-title">
							<span>FAQs</span>
						</div>
	 
						<div className="bot-faqs-questions-container">
							{
								Object.entries(this.FAQs).map(([question, answer]) => {
									return <FaqQuestion key={question} question={question} answer={answer} />
								})
							}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default BotContainer
