import {
  GET_TOPIC_LIST_REQUEST,
  GET_TOPIC_LIST_SUCCESS,
  GET_TOPIC_LIST_FAILURE,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/index'

const initialState = {
  data: [],
  status: IDLE
}

const topicList = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOPIC_LIST_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_TOPIC_LIST_SUCCESS:
      const { data } = action.payload

      return {
        ...state,
        data,
        status: FETCHED
      }
    case GET_TOPIC_LIST_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default topicList
