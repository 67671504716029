import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Field } from '../../index'
import { ValidateRegistrationForm } from '../../../utils'
import { TattleLink } from '../../index'
import config from '../../../config'

import '../authentication.component.css'
import OAuthLoginButton from '../OAuthLoginButton/OAuthLoginButton.component'

/**
 * Register form component
 *
 * @param props
 * @returns {*}
 * @constructor
 */
class RegisterForm extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isValid: true,
      message: ''
    }

    this.handleViewSwitch = this.handleViewSwitch.bind(this)
    this.handleRegisterButtonClick = this.handleRegisterButtonClick.bind(this)
	  this.handleOpenTerms = this.handleOpenTerms.bind(this)
  }

  handleRegisterButtonClick () {
    const {
      onRegisterClick,
      email,
      password,
      confirmPassword,
      name
    } = this.props

    const meta = ValidateRegistrationForm(this.props)

    if (meta.state) {
      this.setState({
        isValid: false,
        message: meta.message
      })
    } else {
      this.setState({
        isValid: true,
        message: ''
      })

      onRegisterClick && onRegisterClick({ email, password, confirmPassword, name })
    }
  }

  handleDiscordRegisterButtonClick () {
    window.open(config.DISCORD_OAUTH2_URL, '_self')
  }

  handleViewSwitch (newActiveViewId) {
    const { handleViewSwitch } = this.props

    this.setState({
      isValid: true,
      message: ''
    })

    handleViewSwitch(newActiveViewId)
  }

  handleOpenTerms () {
	  window.open('/termsofservice', '_blank')
  }

  render () {
    const {
      email,
      password,
      confirmPassword,
      name,
      handleRegistrationFieldChange,
      authStatus,
      authMessage
    } = this.props
    const { isValid, message } = this.state

    const onKeyDown = () => {
      if (email && password && confirmPassword) {
        this.handleRegisterButtonClick()
      }
    }

    return (
      <section className='login-modal_inner'>
        <h3 className='login-modal_title'>Welcome to Tattle!</h3>
        <div className='login-modal_disclaimer'>By continuing, you agree to our <TattleLink to="/termsofservice">Terms of Service</TattleLink> and <TattleLink to="privacypolicy">Privacy Policy</TattleLink></div>
        {!isValid ? (<p className={'error_message'}>{message}</p>) : ('')}
        {authStatus ? (<p className={'error_message'}>{authMessage}</p>) : ('')}
        <OAuthLoginButton oAuthProvider="discord" />
        <div className="login-divider">
          <span>OR</span>
        </div>
        <div className='login-modal_form'>
          <Field
            size='full'
            id='register_email'
            name='email'
            forceValue={email}
            placeholder='Email'
            required
            onChange={handleRegistrationFieldChange}
            onKeyDown={onKeyDown}
          />
          <Field
            size='full'
            id='register_name'
            name='name'
            forceValue={name}
            placeholder='Username'
            onChange={handleRegistrationFieldChange}
            onKeyDown={onKeyDown}
          />
          <Field
            size='full'
            type='password'
            id='register_password'
            name='password'
            forceValue={password}
            placeholder='Password'
            required
            onChange={handleRegistrationFieldChange}
            onKeyDown={onKeyDown}
          />
          <Field
            size='full'
            type='password'
            id='register_confirmPassword'
            name='confirmPassword'
            forceValue={confirmPassword}
            placeholder='Verify Password'
            required
            onChange={handleRegistrationFieldChange}
            onKeyDown={onKeyDown}
          />
          {/* <div className="password-requirements">Your password must be at least 8 characters long, include a number, an uppercase letter and a lowercase letter.</div> */}
          <Button
            label='Sign Up'
            onClick={this.handleRegisterButtonClick}
          />
        </div>
        <div className='login-modal_message'>
          <span>Already have an account? </span>
          <span className='link' onClick={() => this.handleViewSwitch('login')}>Sign In</span>
          <br/>
          <span className='link' onClick={() => this.handleViewSwitch('forgotPassword')}>Forgot Password?</span>
        </div>
      </section>
    )
  }
}

RegisterForm.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  name: PropTypes.string,
  onRegisterClick: PropTypes.func.isRequired,
  handleRegistrationFieldChange: PropTypes.func.isRequired,
  handleViewSwitch: PropTypes.func.isRequired
}

export default RegisterForm
