import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import TattleLink from '../../components/link/link.component'
import AuthenticationContainer from '../authentication/authentication.container'
import './navigation.container.css'
import { Button } from '../../components'
import SubscriptionsList from '../../components/subscription/subscriptions.component'
import { getSubscriptions, clearSearchQuery } from '../../actions'

class NavigationContainer extends PureComponent {
  constructor (props) {
    super(props)

    const path = window.location.pathname.split('/')
    this.state = {
      activeButton: (path && path.length && path[1]) || 'trending',
      loginFormVisible: false,
      formType: 'login',
      subscriptions: []
    }
    
    // Open login modal if query param is set
    const query = new URLSearchParams(window.location.search)
    const loginOpen = query.get('login')
    if (loginOpen) {
      this.state = {
        ...this.state,
        loginFormVisible: true
      }
    }

    this.setActive = this.setActive.bind(this)
    this.toggleLoginForm = this.toggleLoginForm.bind(this)
  }

  componentDidMount () {
    const { auth, user, getSubscriptions } = this.props

    if (auth.isLoggedIn()) {
      const token = { token: auth.getToken() }
      getSubscriptions(user._id, token)
    }
  }

  setActive (button) {
    const { clearSearchQuery } = this.props

    clearSearchQuery()

    this.setState({
      activeButton: button
    })
  }

  toggleLoginForm (type) {
    const formType = (typeof type !== 'string' || !type) ? 'login' : type

    this.setState({
      loginFormVisible: !this.state.loginFormVisible,
      formType: formType
    })
  }

  render () {
    const { activeButton, loginFormVisible, formType } = this.state
    const { auth, subscription } = this.props
    const authenticated = auth.isLoggedIn()

    if (!authenticated) {
      auth.handleLogout()
    }

    return (
      <div className='navigation-container'>
        <div className='navigation-container_inner'>
          <div className='navigation-container_top'>
            <div className='navigation-container_top-logo'>
              <TattleLink to={(authenticated) ? '/home' : '/trending'} onClick={() => (authenticated) ? this.setActive('home') : this.setActive('trending')}>
                <img alt='Tattlenews logo' src='https://storage.googleapis.com/tattle-public-images/site/tattle_logo_mobile.png' className='logo-small' />
              </TattleLink>
            </div>
            <div className='navigation-container_top-menu'>
              {(authenticated) ?
	            <Button
		            theme='transparent'
		            linkTo='/home'
		            icon='home'
		            label='Home'
		            className={'nav-item ' + ((activeButton === 'home') ? 'selected' : '')}
		            onClick={() => this.setActive('home')}
	            />
                  : ''
              }
              <Button
                theme='transparent'
                linkTo='/trending'
                icon='trending'
                label='Trending'
                className={'nav-item ' + ((activeButton === 'trending') ? 'selected' : '')}
                onClick={() => this.setActive('trending')}
              />
              <Button
                theme='transparent'
                linkTo='/explore'
                icon='explore'
                label='Explore'
                className={'nav-item ' + ((activeButton === 'explore' || activeButton === 'timeline') ? 'selected' : '')}
                onClick={() => this.setActive('explore')}
              />
              {(authenticated) ?
                <Button
                  theme='transparent'
                  linkTo='/bookmarks'
                  icon='bookmarks'
                  label='Bookmarks'
                  className={'nav-item ' + ((activeButton === 'bookmarks') ? 'selected' : '')}
                  onClick={() => this.setActive('bookmarks')}
                />
                : ''
              }
              <Button
                theme='transparent'
                linkTo='/bot'
                image='https://storage.googleapis.com/tattle-public-images/site/discordsocial.png'
                label='Discord Bot'
                className={'nav-item ' + ((activeButton === 'bot') ? 'selected' : '')}
                onClick={() => this.setActive('bot')}
              />
              {(!authenticated) ?
                <Button
                  theme='transparent'
                  icon='avatar'
                  label='Sign In'
                  className={'nav-item'}
                  onClick={this.toggleLoginForm}
                />
                : <Button
                  theme='transparent'
                  linkTo='/settings'
                  icon='avatar'
                  label='Profile'
                  className={'nav-item user-nav-item ' + ((activeButton === 'profile') ? 'selected' : '')}
                  onClick={() => this.setActive('profile')}
                />
              }
            </div>
            {(authenticated) ?
	            
              <div className='navigation-container_top-subscriptions'>
                <div className='nav-section subscription'>
                  <div className='section-header'>
                    Subscriptions
                  </div>
                  <SubscriptionsList
                    subscriptions={subscription.data}
                    onSubscriptionClick={() => this.setActive('explore')}
                  />
                </div>
              </div>
              :
            <div className='navigation-container_top-subscriptions sign-up'>
              <div className='join-container'>
                <h3>Join <span className='brand'>Tattle</span> today</h3>
                <p className='message'>Stay updated on all of your favorite topics!</p>
                <Button
                  label={'Sign up'}
                  className={'sign-up_button'}
                  onClick={() => this.toggleLoginForm('registration')}
                />
              </div>
            </div>
            }
          </div>
        </div>
        <AuthenticationContainer
          loginFormVisible={loginFormVisible}
          onClose={this.toggleLoginForm}
          formType={formType}
          auth={auth}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { profile, subscription } = state

  return {
    user: profile.user,
    authenticated: profile.authenticated,
    subscription
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: (profileId, params) => dispatch(getSubscriptions(profileId, params)),
  clearSearchQuery: () => dispatch(clearSearchQuery())
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer)