import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Button} from '../../index'
import ShareComponent from '../../share/share.component'
import {logUserActions} from '../../../utils'
import {Tabs, Tab} from '@mui/material'
import './topicItem.component.css'

class TopicItem extends PureComponent {
    constructor(props) {
        super(props)

        const {seo_name, label} = this.props

        this.state = {
            shareVisible: false,
            sharingOptions: {
                url: `topic/${seo_name}`,
                title: label
            },
            tabValue: 'all'
        }

        this.showShareModal = this.showShareModal.bind(this)
        this.handleShareModalHide = this.handleShareModalHide.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
    }

    showShareModal() {
        this.setState({
            shareVisible: true
        })
    }

    handleShareModalHide() {
        const {label, profile} = this.props
        const share = `Topic: ${label}`

        logUserActions('shareTopic', share, profile.user)

        this.setState({
            shareVisible: false
        })
    }

    handleTabChange(event, value) {
        const {onFeedTypeChange} = this.props

        this.setState({
            tabValue: value
        })

        onFeedTypeChange && onFeedTypeChange(value)
    }

    /**
     * TopicItem component
     *
     * @constructor
     */
    render() {
        const {shareVisible, sharingOptions, tabValue} = this.state
        const {
            label,
            icon,
            isSubscribed,
            onTopicSubscribe,
            onTopicUnsubscribe,
            onBackButtonClick,
            subs_count
        } = this.props

        return (
            <article className="topic-item">
                <div className="topic-item_details">
                    <div className="topic-item_actions">
                        <div className={'button-group-left'}>
                            <Button
                                className="back-button"
                                theme="transparent"
                                icon="arrow_back"
                                onClick={onBackButtonClick}
                            />
                        </div>
                        <div className={'title'}>
                            <img className='topic-icon' src={icon} alt={`Topic icon for ${label}`} />
                            <div>
                                <span className="topic-item_title">{label}</span>
                                <div className="topic-item_subscription-count">{subs_count} {subs_count === 1 ? 'subscriber' : 'subscribers'}</div>
                            </div>
                        </div>
                        <div className={'button-group-right'}>
                            <Button
                                className="share-button"
                                theme="transparent"
                                icon="share"
                                onClick={this.showShareModal}
                            />
                            {isSubscribed ? (
                                <Button label="" className={'unsubscribe-button'}
                                        onClick={onTopicUnsubscribe}/>
                            ) : (
                                <Button label="Subscribe" className={'subscribe-button'}
                                        onClick={onTopicSubscribe}/>
                            )}
                        </div>
                    </div>
                    <div className={'topic-item_navigation'}>
                        <Tabs
                            value={tabValue}
                            onChange={this.handleTabChange}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="All" value={'all'}/>
                            <Tab label="Articles" value={'articles'}/>
                            <Tab label="Videos" value={'videos'}/>
                        </Tabs>
                    </div>
                </div>
                <ShareComponent
                    isVisible={shareVisible}
                    handleShareClose={this.handleShareModalHide}
                    type={'timeline'}
                    {...sharingOptions}
                />
            </article>
        )
    }
}

TopicItem.propTypes = {
    _id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    seo_name: PropTypes.string.isRequired,
    isSubscribed: PropTypes.bool,
    onTopicSubscribe: PropTypes.func,
    onTopicUnsubscribe: PropTypes.func,
    onFeedTypeChange: PropTypes.func
}

TopicItem.defaultProps = {
    topics: []
}

export default TopicItem
