import {
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAILURE,
	IDLE,
	FETCHING,
	FETCHED,
	FAILED
} from '../../actions/index'

const initialState = {
	data: [],
	status: IDLE
}

const categories = (state = initialState, action) => {
	switch (action.type) {
		case GET_CATEGORIES_REQUEST:
			return {
				...state,
				status: FETCHING
			}
		case GET_CATEGORIES_SUCCESS:
			const { data } = action.payload

			return {
				...state,
				data,
				status: FETCHED
			}
		case GET_CATEGORIES_FAILURE:
			return {
				...state,
				status: FAILED
			}
		default:
			return state
	}
}

export default categories
