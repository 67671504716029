import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {FETCHING, updateProfile} from "../../../actions";
import {Button, LoadingMask, Title, TattleLink} from "../../../components";
import './account.setting.page.css'

class AccountSettingPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}

        this.onSaveClick = this.onSaveClick.bind(this)
        this.onBackButtonClick = this.onBackButtonClick.bind(this)
    }

    onSaveClick(fields) {
        const { updateProfile, auth, user } = this.props
        const authToken = {
            token: auth.getToken()
        }

        return updateProfile && updateProfile(user._id, fields, authToken)
    }

    onBackButtonClick () {
        const {history} = this.props

        history.back()
    }

    render() {
        const { fetchingStatus, user, auth } = this.props
        if (!user || !user.username) {
            return auth.handleLogout()
        }

        const fields = {
            display_name: user.display_name,
            username: user.username,
            phone: user.phone || ''
        }

        return (
            <div className='account-setting-page'>
                    {fetchingStatus === FETCHING ? (
                        <LoadingMask
                        />
                    ) : (
                        <Title className={'centered'}>
                            <Button
                                className="back-button"
                                theme="transparent"
                                icon="arrow_back"
                                onClick={this.onBackButtonClick}
                                label='Account Details'
                            />
                        </Title>
                    )}
                    
                <div className='account-setting-page_inner'>
                    <div className='profile-row'>
                        <ul className='settings-list'>
                            <TattleLink
                                to={'/settings/updateUsername'}
                                className='settings-link'
                            >
                                <li>
                                    <div>Username</div>
                                    <div className='userdata'>{fields.display_name}</div>
                                    <div className='more-arrow'>
                                        <i className='icon icon-arrow_forward'/>
                                    </div>
                                </li>
                            </TattleLink>

                            {/* <TattleLink
                                to={'/settings/updateEmail'}
                                className='settings-link'
                            > */}
                                <li>
                                    <div>Email</div>
                                    <div className='userdata'>{fields.username}</div>
                                    {/* <div className='more-arrow'>
                                        <i className='icon icon-arrow_forward'/>
                                    </div> */}
                                </li>
                            {/* </TattleLink> */}

                            <TattleLink
                                to={'/settings/updatePhone'}
                                className='settings-link'
                            >
                                <li>
                                    <div>Phone</div>
                                    <div className='userdata'>{fields.phone}</div>
                                    <div className='more-arrow'>
                                        <i className='icon icon-arrow_forward'/>
                                    </div>
                                </li>
                            </TattleLink>

                            <TattleLink
                                to={'/settings/updatePassword'}
                                className='settings-link'
                            >
                                <li>
                                    <div>Change password</div>
                                    
                                    <div className='more-arrow'>
                                        <i className='icon icon-arrow_forward'/>
                                    </div>
                                </li>
                            </TattleLink>
                        </ul>
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    const {profile} = state

    return {
        fetchingStatus: profile.status,
        user: profile.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateProfile: (id, body, auth) => dispatch(updateProfile(id, body, auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingPage)