import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {FETCHING, getProfile, cancelSubscription, reactivateSubscription, updateProfile} from "../../../actions";
import {Button, LoadingMask, Title} from "../../../components";
import './membership.setting.page.css'
import AddServerModal from './addServerModal/addServerModal'
import config from '../../../config'

class MembershipSettingPage extends PureComponent {
    constructor(props) {
        super(props)
        const {auth, user, getProfile} = this.props

        this.state = {
            isAddServerModalVisible: false
        }

        this.onBackButtonClick = this.onBackButtonClick.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.toggleAddServerModal = this.toggleAddServerModal.bind(this)

        //cache busting for profile
        getProfile && getProfile(user._id, {token: auth.getToken()})
    }

    onBackButtonClick () {
        const {history, auth, user, getProfile} = this.props

        getProfile && getProfile(user._id, {token: auth.getToken()})
            .then(() => {
                history.back()
            })
    }

    toggleAddServerModal() {
        const { isAddServerModalVisible } = this.state
        this.setState({
            isAddServerModalVisible: !isAddServerModalVisible
        })
    }

    updateProfile(action, fields) {
        const { updateProfile, auth, user } = this.props
        const authToken = {
            token: auth.getToken()
        }

        let data = {}

        if (action === 'add') {
            data = {
                servers: [{
                    server_id: fields.server_id,
                    server_name: fields.server_name
                }]
            }
        } else {
            data = {servers: []}
        }

        return updateProfile && updateProfile(user._id, data, authToken)
    }

    render() {
        const {
            isAddServerModalVisible
        } = this.state
        const { fetchingStatus, user } = this.props
        const { entitlement, servers } = user
        let sub = {}
        let guild = {}

        if (!entitlement) {
            sub.paid = false
        } else {
            sub = entitlement
            const today = new Date()
            const expiration = (entitlement.expires === null)?null:new Date(entitlement.expires)
            sub.paid = (entitlement.status === 'paid' || entitlement.status==="ACTIVE")?true:false
            sub.status = (expiration === null)? "ACTIVE":"SUSPENDED"
            sub.paid = (expiration !== null && expiration < today)? false: sub.paid
            sub.legacy = (!entitlement.stripe_customer_id)?true:false
        }   

        if (servers && servers.length) {
            guild = servers[0]
        }

        return (
            <div className='membership-setting-page'>
                {fetchingStatus === FETCHING ? (
                    <LoadingMask
                    />
                ) : (
                    <Title className={'centered'}>
                        <Button
                            className="back-button"
                            theme="transparent"
                            icon="arrow_back"
                            onClick={this.onBackButtonClick}
                            label='Settings'
                        />
                        <div className='title-label'>Your Membership</div>
                    </Title>
                )}
                <div className='membership-setting-page_inner'>
                    <div className='membership-type_section'>
                        <div className='header'>
                            {sub.paid ?
                                (
                                    <div className=''>
                                        <div className='header-section-label'>TattlePro</div>
                                        {(sub.status === "ACTIVE" && !sub.legacy)?
                                            <span className='membership-bill-date'>Next billing date: {moment(sub.current_period_end).format('MMM DD, YYYY')}</span>
                                            :
                                            <span className='membership-bill-date'>Expiration date: {moment(sub.expires).format('MMM DD, YYYY')}</span>
                                        }
                                    </div>
                                ) : (
                                    <div className='header-section-label'>TattleFree</div>
                                )
                            }

                            <span className={sub.paid ? 'call-to-action paid' : 'call-to-action'}>Manage membership</span>
                        </div>
                        <div className='details'>
                            <div className='details_plan'>
                                <span className='details_plan-label'>Plan:
                                    <span className='details_plan-type_label'>{sub.paid ? ' TattlePro' : ' Free'}</span>
                                </span>
                                {!sub.paid ?
                                    <a className='details_plan-action' href={`${config.STRIPE_BUY_URL}?client_reference_id=${user._id}`}>Upgrade Plan</a>
                                    :
                                    sub.legacy ?
                                        <span>Legacy Account</span>
                                    :(
                                        <a className='details_plan-action' href={`${config.STRIPE_CUSTOMER_PORTAL}`} >
                                        {(sub.status === "ACTIVE")?
                                            "Cancel Plan"
                                            :
                                            "Reactivate Plan"
                                        }
                                    
                                        </a>
                                    )
                                } 
                            </div>
                        </div>
                    </div>
                    
                    {sub.paid ? (
                    <div className='active-servers_section'>
                        <div className='header'>
                            <div className='header-section-label'>Active Servers</div>
                            <span className='call-to-action'>Manage servers</span>
                        </div>
                        <div className='details'>
                            <div className='details_server'>
                                {(guild && guild.server_id) ? (
                                    <>
                                    <span className='details_server-label'>{guild.server_name}</span>
                                    <span className='details_server-action right' onClick={() => this.updateProfile('remove', {})}>Remove</span>
                                    </>
                                ) : (
                                    <span className='details_server-action' onClick={() => this.toggleAddServerModal()}>Add Server</span>
                                )}

                            </div>
                        </div>
                    </div>
                        ) : '' }
                </div>

                <AddServerModal
                    isVisible={isAddServerModalVisible}
                    onClose={this.toggleAddServerModal}
                    onUpdate={this.updateProfile}
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    const { profile } = state

    return {
        fetchingStatus: profile.status,
        user: profile.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    getProfile: (id, auth) => dispatch(getProfile(id, auth)),
    updateProfile: (id, body, auth) => dispatch(updateProfile(id, body, auth)),
    cancelSubscription: (entitlement, auth) => dispatch(cancelSubscription(entitlement,auth)),
    reactivateSubscription: (entitlement, auth) => dispatch(reactivateSubscription(entitlement,auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(MembershipSettingPage)