import {
  FAILED,
  FETCHED,
  FETCHING,
  GET_POLICY_REQUEST,
  GET_POLICY_SUCCESS,
  GET_POLICY_FAILURE
} from '../../actions'

const initialState = {}

const policy = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLICY_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_POLICY_SUCCESS:
      const { data } = action.payload

      return {
        ...state,
        ...data,
        status: FETCHED
      }
    case GET_POLICY_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default policy
