import { combineReducers } from 'redux'
import profile from './profile/profile.reducer'
import topic from './topic'
import article from './article'
import search from './search/search.reducer'
import policy from './policy/policy.reducer'
import feedback from './feedback/feedback.reducer'
import subscription from './subscription/subscription.reducer'
import bookmark from './bookmark/bookmark.reducer'
import categories from './category/category.reducer'

export default combineReducers({
  profile,
  topic,
  article,
  search,
  policy,
  feedback,
  subscription,
  bookmark,
	categories
})
